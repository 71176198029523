import axios, { CancelTokenSource } from 'axios'
import { IFileToUpload } from '../types'
import { v4 as uuidv4 } from 'uuid'
import { immerable } from 'immer'
import { isImageFromMime } from 'modules/media/utils'
import { Identifier } from 'core/types'

export class FileToUpload implements IFileToUpload {
  [immerable]? = true

  file: File

  loaded: boolean

  loading: boolean

  id: string

  preview?: string

  progress: number

  mediaId?: Identifier

  uploadError?: any

  cancelToken: CancelTokenSource

  constructor(file: File) {
    this.file = file
    this.loaded = false
    this.loading = false
    this.id = uuidv4()
    this.progress = 0
    this.uploadError = undefined
    this.cancelToken = axios.CancelToken.source()

    if (isImageFromMime(file.type)) {
      this.preview = URL.createObjectURL(file)
    }
  }
}
