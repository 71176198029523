// Core
import React, { FC, useState } from 'react'
import {
  makeStyles,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core'
import { Group, ExpandMore, Add, Edit } from '@material-ui/icons'
import { Link } from 'react-router-dom'
// Components
import DeleteAction from './delete-action'
// Hooks
import { useTypePermissions } from 'modules/new-entity/hooks'
// Types
import { ResourceVersion } from '../types'

const useStyles = makeStyles((theme) => ({
  control: {
    display: 'inline-flex',
    alignItems: 'stretch',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    overflow: 'hidden',
  },
  button: {
    padding: 0,
    paddingRight: theme.spacing(2),
  },
  action: {
    padding: theme.spacing(0, 1),
    borderRadius: 0,
  },
  controlIcon: {
    padding: 7,
    paddingLeft: theme.spacing(2),
    background: theme.palette.grey[300],
    color: theme.palette.grey[700],
  },
  search: {
    padding: theme.spacing(0, 1),
    background: theme.palette.background.default,
  },
}))

type Props = {
  options: ResourceVersion[]
  label: string
  currentId: number
  originalId: number
  onCreate: () => void
  onEdit: (id: number) => void
  hasEdit: boolean
  getEditPath: (id: number) => string
  refreshData: () => void
  isFetching: boolean
  resource: string
  disabled?: boolean
  entityType?: string
}

const VersionsSelect: FC<Props> = (props) => {
  const {
    options,
    label,
    onCreate,
    onEdit,
    getEditPath,
    hasEdit,
    currentId,
    originalId,
    refreshData,
    isFetching,
    resource,
    disabled,
    entityType,
  } = props

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const menuOpen = Boolean(anchorEl)

  const permissions = useTypePermissions({
    slug: entityType || '',
    '@type': resource === 'widgets' ? 'WidgetType' : 'EntityType',
  })

  return (
    <Box mr={2} mb={2} className={classes.control}>
      <Button
        startIcon={
          <Box display="inline-flex" alignItems="center" className={classes.controlIcon}>
            <Group />
            <ExpandMore fontSize="small" />
          </Box>
        }
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-controls="versions-menu"
        aria-haspopup="true"
        className={classes.button}
      >
        {label}
      </Button>
      {hasEdit && (
        <IconButton
          className={classes.action}
          onClick={() => onEdit(currentId)}
          disabled={disabled}
        >
          <Edit fontSize="small" />
        </IconButton>
      )}
      <IconButton className={classes.action} onClick={onCreate} disabled={disabled}>
        <Add />
      </IconButton>
      {isFetching && (
        <IconButton className={classes.action}>
          <CircularProgress size={15} />
        </IconButton>
      )}
      <Menu
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        open={menuOpen}
        PaperProps={{ style: { minWidth: 250, paddingTop: 0 } }}
        id="versions-menu"
      >
        {options.length === 0 && <Typography align="center">No versions</Typography>}
        {options.map((option) => (
          <MenuItem
            key={option.id}
            selected={currentId === option.id}
            {...(currentId !== option.id ? { component: Link, to: getEditPath(option.id) } : {})}
            onClick={() => setAnchorEl(null)}
          >
            <Typography variant="inherit">
              {option.name || `${option.originalLabel} ${option.id}`}
            </Typography>
            {originalId !== option.id && currentId !== option.id && (
              <ListItemSecondaryAction>
                {permissions.canDelete && (
                  <DeleteAction id={option.id} refreshData={refreshData} resource={resource} />
                )}
                {permissions.canEdit && (
                  <IconButton onClick={() => onEdit(option.id)} size="small">
                    <Edit />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default VersionsSelect
