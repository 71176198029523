// Core
import { takeEvery, select, put, call } from 'redux-saga/effects'
// Redux
import { ActionTypes, IDeleteFileAction } from '../types'
import { fetchFilesList } from '../actions'
import { filesListParamsSelector } from '../selector'
// Services
import { httpService } from 'core/data'
// Types
import { ListParams } from 'modules/media/types'
import { showConfirm } from '../../../../../core/store/modal'

function* deleteFileWorker(action: IDeleteFileAction) {
  try {
    const { file } = action.payload
    const params: ListParams = yield select(filesListParamsSelector)

    const confirmed = yield call(showConfirm, 'Are you sure?')

    if (confirmed) {
      yield httpService.delete(`media/${file.id}`)

      yield put(fetchFilesList(params, false))
    }
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export function* deleteFileWatcher() {
  yield takeEvery(ActionTypes.DELETE_FILE, deleteFileWorker)
}
