// Core
import React, { FC } from 'react'
import { LinearProgress } from '@material-ui/core'
import { useGetOne } from 'core/data'
// Utils
import { getIdFromIri } from 'core/utils/data'
import { getFormatFromMime } from 'modules/media/utils'
import { useTranslation } from 'react-i18next'
// Styles
import useStyles from './file-details.styles'

type Props = {
  data: any
  children?: never
}

const FileDetails: FC<Props> = (props) => {
  const { data } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: userData, loading } = useGetOne('users', getIdFromIri(data.createdBy))

  const isImage = !Array.isArray(data.dimensions)

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <span className={classes.title}>{t('media.file-name')}:</span> {data.filename}
      </div>
      <div className={classes.item}>
        <span className={classes.title}>{t('media.file-type')}:</span>
        <span style={{ textTransform: 'uppercase' }}>{getFormatFromMime(data.mimeType)}</span>
      </div>
      <div className={classes.item}>
        <span className={classes.title}>{t('media.uploaded-on')}:</span> {data.createdAt}
      </div>
      <div className={classes.item}>
        <span className={classes.title}>{t('media.file-size')}:</span> {data.size} KB
      </div>
      <div className={classes.item}>
        <span className={classes.title}>{t('media.uploaded-by')}: </span>
        {loading ? <LinearProgress color="primary" /> : userData?.email}
      </div>
      {isImage && (
        <div className={classes.item}>
          <span className={classes.title}>{t('media.dimensions')}:</span> {data.dimensions.width} x{' '}
          {data.dimensions.height}
        </div>
      )}
    </div>
  )
}

export default FileDetails
