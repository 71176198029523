// Core
import React, { FC } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
// Components
import { FileItem } from './components'
// Types
import { IFileToUpload } from '../../../../types'

type TProps = {
  data: IFileToUpload[]
  isWaitingUpload: boolean
}

const FileList: FC<TProps> = (props) => {
  const { data, isWaitingUpload } = props

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="right">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <FileItem key={item.id} data={item} isWaitingUpload={isWaitingUpload} />
        ))}
      </TableBody>
    </Table>
  )
}

export default FileList
