// Core
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { useDataViewContext } from './data-view-context'

type Props = {
  children?: never
}

const TableFooter: FC<Props> = () => {
  const { page, setPage, totalItems, perPage, isDataLoading } = useDataViewContext()
  const count = useMemo(() => Math.ceil(totalItems / perPage), [perPage, totalItems])

  const [prevState, setPrevState] = useState({
    count,
    page,
  })

  useEffect(() => {
    if (!page || !count) return

    setPrevState({ count, page })
  }, [count, page])

  const combinedCount = isDataLoading ? prevState.count : count
  const combinedPage = isDataLoading ? prevState.page : page

  return (
    <Box py={1} borderTop={1} style={{ borderColor: '#e0e0e0' }}>
      <Pagination
        disabled={isDataLoading}
        count={combinedCount}
        page={combinedPage}
        onChange={(_, p) => setPage(p)}
        color="primary"
        showFirstButton
        showLastButton
      />
    </Box>
  )
}

export default TableFooter
