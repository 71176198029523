import React, { FC } from 'react'
// UI
import { Grid, Paper } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

type TProps = {}

const FormSkeleton: FC<TProps> = () => {
  return (
    <Grid container spacing={2} style={{ padding: '0 24px 8px 24px', backgroundColor: '#F4F6F8' }}>
      <Grid item xs={8}>
        <Paper style={{ padding: '20px 16px' }}>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="30%" height={15} style={{ marginBottom: 5 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="30%" height={15} style={{ marginBottom: 5 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="30%" height={15} style={{ marginBottom: 5 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="30%" height={15} style={{ marginBottom: 5 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="30%" height={15} style={{ marginBottom: 5 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="30%" height={15} style={{ marginBottom: 25 }} />
          </Grid>
          <Skeleton variant="rect" width="60%" height={340} />
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper style={{ padding: '20px 16px' }}>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
          </Grid>
          <Skeleton variant="rect" width="100%" height={1} style={{ margin: '40px 0 35px' }} />
          <Grid item xs={12} container justifyContent="flex-end">
            <Skeleton variant="rect" width={80} height={36} />
            <Skeleton variant="rect" width={80} height={36} style={{ marginLeft: 16 }} />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default FormSkeleton
