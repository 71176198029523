import React, { FC, useCallback } from 'react'
import { Button, Divider, Grid, Paper } from '@material-ui/core'
import { FormLang } from 'core/data'
import { TextField } from 'core/form'
import useStyles from './edit-file.styles'
import { useTranslation } from 'react-i18next'
import { useConfirmation } from 'core/confirmation'
import { Permissions, useAppContext } from 'core/app'

type EditFileProps = {
  removeHandler: () => void
}

const EditFile: FC<EditFileProps> = ({ removeHandler }) => {
  const {
    actions: { userCan },
  } = useAppContext()
  const classes = useStyles()
  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()

  const removeWithConfirmation = useCallback(
    (onSuccess: () => void) => {
      setConfirmation({
        open: true,
        title: t('global.delete'),
        content: t('notify.delete'),
        onSuccess,
      })
    },
    [setConfirmation, t]
  )

  return (
    <Paper style={{ padding: '20px 16px' }}>
      <FormLang />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField name="sourceUrl" label={t('media.url')} disabled />
        </Grid>
        <Grid item xs={12}>
          <TextField name="title" label={t('media.title')} />
        </Grid>
        <Grid item xs={12}>
          <TextField name="name" label={t('media.name')} required />
        </Grid>
        <Grid item xs={12}>
          <TextField name="alt" label={t('media.alt')} />
        </Grid>
        <Grid item xs={12}>
          <TextField name="description" label={t('media.desc')} multiline />
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        {userCan('media', Permissions.DELETE, 'system') && (
          <Button
            type="button"
            color="default"
            variant="contained"
            onClick={() => removeWithConfirmation(() => removeHandler())}
          >
            {t('global.delete')}
          </Button>
        )}

        {userCan('media', Permissions.EDIT, 'system') && (
          <Button type="submit" variant="contained" color="primary">
            {t('global.update')}
          </Button>
        )}
      </div>
    </Paper>
  )
}

export default EditFile
