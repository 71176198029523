// Core
import React, { FC } from 'react'
import { Box, Paper } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { defaultThemeSizes } from 'core/themes/default/default'
// Context
import { DataViewProvider } from './data-view-context'
// Components
import PageHeader from 'modules/layout/components/page-header'
import TableHeader from './table-header'
import Filters from 'modules/eav/entity/filters'
import Table from './table'
import TableFooter from './table-footer'
// Hooks
import useController from './use-controller'

type Props = {
  typeId: number
}

const HeaderSkeleton = (
  <Box display="flex" p={1}>
    <Skeleton variant="rect" height={40} width={227} />
    <Skeleton variant="rect" height={40} width={85} style={{ margin: '0 10px' }} />
    <Skeleton variant="rect" height={40} width={85} />
    <Skeleton variant="rect" height={40} width={190} style={{ marginLeft: 'auto' }} />
  </Box>
)

const PageHeaderSkeleton = (
  <Box
    display="flex"
    px={2}
    py={2}
    height={defaultThemeSizes.pageHeaderHeight + 1}
    style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
    bgcolor="common.white"
  >
    <Skeleton variant="rect" height={33} width={227} />
  </Box>
)

const DataView: FC<Props> = (props) => {
  const { typeId } = props
  const controllerProps = useController({ typeId })
  const { isColumnsLoading, filters, attributes, entityType } = controllerProps

  if (controllerProps.hasError) return <div>Error</div>

  return (
    <DataViewProvider value={controllerProps}>
      {isColumnsLoading ? PageHeaderSkeleton : <PageHeader title={entityType.name} />}
      <Box m={1} component={Paper}>
        {isColumnsLoading ? HeaderSkeleton : <TableHeader />}
        <Box py={1} px={1}>
          <Filters
            isLoading={isColumnsLoading}
            data={attributes}
            selectedFilters={filters.selectedFilters}
            addFilter={filters.addFilter}
            removeFilter={filters.removeFilter}
            changeFilterCondition={filters.changeFilterCondition}
            changeFilterValue={filters.changeFilterValue}
            clearAllFilters={filters.clearAllFilters}
          />
        </Box>
        <Table />
        <TableFooter />
      </Box>
    </DataViewProvider>
  )
}

export default DataView
