import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { toggleManagerModal } from 'modules/media/store'
import { IFile } from '../types'

export type MediaManagerModalOpen = () => (
  isOpen: boolean,
  onFileSelect?: (file: IFile) => void
) => void

export const useMediaManagerModalOpen: MediaManagerModalOpen = () => {
  const dispatch = useDispatch()

  return useCallback(
    (isOpen, onFileSelect) => {
      dispatch(toggleManagerModal(true, onFileSelect))
    },
    [dispatch]
  )
}
