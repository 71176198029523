import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    fileName: {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '250px',
      },
      '& .MuiAvatar-root': {
        marginRight: 16,
      },
    },
  })
)

export default styles
