// Core
import { takeEvery, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
// Redux
import { ActionTypes, IRemoveFolder } from '../types'
import { removeFolderSuccess, selectFolder } from '../actions'
// Services
import { httpService } from 'core/data'
import { showConfirm } from 'core/store/modal'

function* removeFolderWorker(action: IRemoveFolder) {
  try {
    const { id, option, onDelete } = action.payload

    const confirmed = yield call(showConfirm, 'Are you sure?')

    if (confirmed) {
      const params: any = {
        force: true,
      }

      if (option === 'root') {
        delete params.force
      }

      yield put(removeFolderSuccess(id))
      yield call(httpService.delete, `/media_folders/${id}`, { params })
      yield put(selectFolder('root'))
      if (onDelete) onDelete()
      toast.success('Folder removed successfully')
    }
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export function* removeFolderWatcher() {
  yield takeEvery(ActionTypes.REMOVE_FOLDER, removeFolderWorker)
}
