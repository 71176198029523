import React, { FC } from 'react'
import { Box, FormLabel, Paper } from '@material-ui/core'
import { schemes } from './schemes'
// hooks
import { useSitesContext } from 'modules/sites'
// types
import { SectionBackground } from 'core/types/layouts'
// Styles
import useStyles from './section-colorpicker.styles'

type Props = {
  label: string
  color: string
  clickHandler: (colorName: SectionBackground) => void
}

const SectionBg: FC<Props> = (props) => {
  const classes = useStyles()
  const { label, color, clickHandler } = props

  const {
    siteSettings: {
      colorScheme: { value: colorSchemeValue },
    },
  } = useSitesContext()

  const colors = schemes[colorSchemeValue].container_bg

  return (
    <Box className={classes.root}>
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
      <Box className={classes.list}>
        {colors.map((colorItem, index) => {
          return (
            <div key={index}>
              <Paper
                className={classes.paper}
                variant="outlined"
                onClick={() => clickHandler(colorItem.name)}
                data-value={index}
                style={{
                  background: colorItem.value,
                }}
              >
                {color === colorItem.name && <span className={classes.checked} />}
              </Paper>
            </div>
          )
        })}
      </Box>
    </Box>
  )
}

export default SectionBg
