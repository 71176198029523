// Core
import { takeEvery, call, put } from 'redux-saga/effects'
// Redux
import { ActionTypes, IFetchListAction } from '../types'
import { fetchFilesListSuccess } from '../actions'
// Services
import { httpService } from 'core/data'
// Types
import { ApiResponse, HydraResponse } from 'core/types'

function* fetchListWorker(action: IFetchListAction) {
  try {
    const { params } = action.payload

    if (params?.folder === 'root') {
      delete params.folder
    }

    const { data }: ApiResponse<HydraResponse> = yield call(httpService.get, '/media', { params })

    yield put(fetchFilesListSuccess(data['hydra:member'], data['hydra:totalItems']))
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export function* fetchListWatcher() {
  yield takeEvery(ActionTypes.FETCH_LIST, fetchListWorker)
}
