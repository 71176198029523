// Core
import React, { FC } from 'react'
import { Icon, IconButton, TableCell, TableRow, TextField } from '@material-ui/core'
// Hooks
import { Permissions, useAppContext } from 'core/app'
// Utils
import { trailingSlash, urlChecker } from 'core/utils'
// Types
import { Redirect, useRedirectController } from 'modules/redirects'

type Props = {
  children?: never
  data: Redirect
  siteUrl: string
}

const TableItem: FC<Props> = (props) => {
  const { data, siteUrl } = props
  const {
    actions: { userCan },
  } = useAppContext()
  const { mutation, deleteHandler, editHandler, isEdit, form } = useRedirectController(data)
  const isUrl = urlChecker(data.toUrl)

  const rowStyles: any = mutation.isLoading ? { opacity: 0.5, pointerEvents: 'none' } : {}

  return (
    <TableRow style={rowStyles}>
      <TableCell>
        <b>{data.id}</b>
      </TableCell>
      <TableCell>
        {isEdit ? (
          <TextField
            value={form.values.type}
            name="type"
            onChange={form.handleChange}
            select
            SelectProps={{ native: true }}
            error={Boolean(form.errors.type)}
          >
            <option value="301">301</option>
            <option value="302">302</option>
          </TextField>
        ) : (
          <b>{data.type}</b>
        )}
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <b>{siteUrl}/</b>
          {isEdit ? (
            <>
              <TextField
                error={Boolean(form.errors.fromUrl)}
                helperText={form.errors.fromUrl}
                value={form.values.fromUrl}
                name="fromUrl"
                onChange={form.handleChange}
              />
              /
            </>
          ) : (
            <span>{trailingSlash(data.fromUrl)}</span>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isUrl && <b>{siteUrl}/</b>}
          {isEdit ? (
            <TextField
              error={Boolean(form.errors.toUrl)}
              helperText={form.errors.toUrl}
              value={form.values.toUrl}
              name="toUrl"
              onChange={form.handleChange}
            />
          ) : (
            <span>{data.toUrl}</span>
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        {userCan('redirects', Permissions.EDIT, 'system') && (
          <IconButton onClick={editHandler}>
            <Icon className={`icon-${isEdit ? 'check' : 'edit'}`} />
          </IconButton>
        )}
        {userCan('redirects', Permissions.DELETE, 'system') && (
          <IconButton onClick={deleteHandler}>
            <Icon className={`icon-${isEdit ? 'x' : 'delete'}`} />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

export default TableItem
