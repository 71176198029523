import { httpService } from 'core/data'
import { HydraResponse } from 'core/types'
import { getIdFromIri } from 'core/utils'
import { useGetActiveSite } from 'modules/sites'
import { useQuery, UseQueryOptions } from 'react-query'

type Folder = {
  id: number
  site: string
  parent: string | null
}

type FoldersRes = HydraResponse<Folder>

export const useGetDefaultFolder = (options: UseQueryOptions<Folder>) => {
  const activeSite = useGetActiveSite()

  return useQuery(
    ['default-folder'],
    async () => {
      const folders = await httpService.get<FoldersRes>('/media_folders').then((res) => res.data)

      const findFolder = folders['hydra:member'].find(
        (folder) => +getIdFromIri(folder.site) === activeSite?.id && !folder.parent
      )

      return findFolder
    },
    options
  )
}
