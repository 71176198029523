// Core
import { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
// Hooks
import { useRelationsError } from './use-relations-error'
import { useGetActiveSite } from 'modules/sites'
import { useNotify } from 'core/hooks'
import { useConfirmation } from 'core/confirmation'
// Services
import { httpService } from 'core/data'
import { RevalidationService } from 'core/utils/revalidation-service'
// Types
import { IEntity } from 'modules/new-entity/types'

type Params = {
  invalidateKey?: string
  onSuccess?: () => void
  isWidgetType?: boolean
}

export const useDeleteEntity = (entityId: number, params: Params = {}) => {
  const { invalidateKey, onSuccess, isWidgetType } = params
  const queryClient = useQueryClient()
  const activeSite = useGetActiveSite()
  const { notifyRelationsError, isRelationsError } = useRelationsError()
  const { setConfirmation } = useConfirmation()
  const notify = useNotify()
  const { t } = useTranslation()
  const mainPath = isWidgetType ? 'widgets' : 'entities'

  const deleteM = useMutation(
    async () => {
      const entityReq = httpService.get<IEntity>(`/${mainPath}/${entityId}`).then((res) => res.data)
      const entityRefsReq = RevalidationService.getEntityReferences(entityId, 'entity')
      const [entity, entityRefs] = await Promise.all([entityReq, entityRefsReq])
      const pageUrl = entity.entityUrls?.[0]?.url

      await httpService.delete(`/${mainPath}/${entityId}`)

      if (activeSite) {
        await RevalidationService.revalidateEntity(
          activeSite.url,
          entityId,
          'entity',
          pageUrl,
          entityRefs
        )
      }
    },
    {
      onSuccess: (data) => {
        if (invalidateKey) {
          queryClient.invalidateQueries(invalidateKey)
        }
        notify(`Entity ${t('actions.deleted')}`, { type: 'success' })
        if (onSuccess) onSuccess()
      },
      onError: (error) => {
        if (isRelationsError(error)) {
          notifyRelationsError(error)
        } else {
          notify('Something went wrong', { type: 'error' })
        }
      },
    }
  )

  const deleteEntity = useCallback(() => {
    setConfirmation({
      open: true,
      title: t('global.delete'),
      content: t('notify.delete'),
      onSuccess: deleteM.mutate,
    })
  }, [deleteM.mutate, setConfirmation, t])

  return { deleteEntity, deleteEntityM: deleteM }
}
