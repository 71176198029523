import { Action } from 'redux'
import { FolderTreeItem, IFolder } from 'modules/media/types'

export interface IMediaFolderState {
  data: { [id: string]: IFolder }
  folderTree: FolderTreeItem[]
  selectedFolder: string | undefined
  expanded: string[]
  formModal: {
    open: boolean
    data: any
    type: 'create' | 'edit'
  }
}

export enum ActionTypes {
  GET_FOLDERS = '@@media/GET_FOLDERS',
  GET_FOLDERS_SUCCESS = '@@media/GET_FOLDERS_SUCCESS',

  SAVE_FOLDER = '@@media/SAVE_FOLDER',
  SAVE_FOLDER_SUCCESS = '@@media/SAVE_FOLDER_SUCCESS',
  UPDATE_FOLDER_DATA = '@@media/UPDATE_FOLDER_DATA',
  REMOVE_FOLDER = '@@media/REMOVE_FOLDER',
  REMOVE_FOLDER_SUCCESS = '@@media/REMOVE_FOLDER_SUCCESS',

  SELECT_FOLDER = '@@media/SELECT_FOLDER',
  TOGGLE_FOLDER_EXPAND = '@@media/TOGGLE_FOLDER_EXPAND',

  TOGGLE_FORM_MODAL = '@@media/TOGGLE_FORM_MODAL',
}

export interface IGetFolders extends Action<ActionTypes.GET_FOLDERS> {
  payload: {
    folder: string
    needExpand?: boolean
  }
}

export interface IGetFoldersSuccess extends Action<ActionTypes.GET_FOLDERS_SUCCESS> {
  payload: {
    data: any
  }
}

export interface ISaveFolder extends Action<ActionTypes.SAVE_FOLDER> {
  payload: {
    data: any
    type: 'create' | 'edit'
  }
}

export interface ISaveFolderSuccess extends Action<ActionTypes.SAVE_FOLDER_SUCCESS> {
  payload: {
    data: any
    type: 'create' | 'edit'
  }
}

export interface ISelectFolder extends Action<ActionTypes.SELECT_FOLDER> {
  payload: { id: string }
}

export interface IToggleFolderExpand extends Action<ActionTypes.TOGGLE_FOLDER_EXPAND> {
  payload: { id: string }
}

export interface IToggleFormModal extends Action<ActionTypes.TOGGLE_FORM_MODAL> {
  payload: {
    open: boolean
    data?: any
    type?: 'create' | 'edit'
  }
}

export interface IRemoveFolder extends Action<ActionTypes.REMOVE_FOLDER> {
  payload: { id: string; option: string; onDelete?: () => void }
}

export interface IRemoveFolderSuccess extends Action<ActionTypes.REMOVE_FOLDER_SUCCESS> {
  payload: { id: string }
}

export interface IUpdateFolderData extends Action<ActionTypes.UPDATE_FOLDER_DATA> {
  readonly payload: { id: string; data: any }
}
