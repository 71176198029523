// Core
import { takeEvery, put, call, delay } from 'redux-saga/effects'
// Redux
import { ActionTypes, IGetFolders } from '../types'
import { getFoldersSuccess } from '../actions'
import { httpService } from 'core/data'

function* getFoldersWorker(action: IGetFolders) {
  try {
    const response = yield call(httpService.get, '/media_folders', {
      params: {
        // parent: action.payload.folder === 'root' ? null : action.payload.folder
      },
    })

    yield put(getFoldersSuccess(response.data['hydra:member']))
    yield delay(10)
    if (action.payload.needExpand && response.data['hydra:member'].length > 0) {
      // yield put(toggleFolderExpand(action.payload.folder))
    }
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export function* getFoldersWatcher() {
  yield takeEvery(ActionTypes.GET_FOLDERS, getFoldersWorker)
}
