export enum AttributeTypesList {
  string = 'string',
  string_l10n = 'string_l10n',
  text = 'text',
  text_l10n = 'text_l10n',
  slug = 'slug',
  integer = 'integer',
  decimal = 'decimal',
  select = 'select',
  multi_select = 'multi_select',
  boolean = 'boolean',
  date_time = 'date_time',
  text_editor = 'text_editor',
  text_editor_l10n = 'text_editor_l10n',
  // References
  // direct
  reference_one_to_one = 'reference_one_to_one',
  reference_many_to_one = 'reference_many_to_one',
  reference_many_to_many = 'reference_many_to_many',
  reference_many_to_one_multiple_entity_types = 'reference_many_to_one_multiple_entity_types',
  // reversed
  reference_one_to_one_reversed = 'reference_one_to_one_reversed',
  reference_many_to_many_reversed = 'reference_many_to_many_reversed',
  reference_many_to_many_multiple_types = 'reference_many_to_many_multiple_types',
  reference_one_to_many_multiple_types = 'reference_one_to_many_multiple_types', // reversed
  reference_many_to_one_multiple_types = 'reference_many_to_one_multiple_types',
  reference_one_to_many = 'reference_one_to_many',
  // for slug creation (hierarchical)
  reference_many_to_one_hierarchical = 'reference_many_to_one_hierarchical',

  // repeater
  repeater = 'repeater',
  group = 'group',
  // image
  image = 'image',
  attachment = 'attachment',
}

export const reference = [
  AttributeTypesList.reference_one_to_one,
  AttributeTypesList.reference_many_to_one,
  AttributeTypesList.reference_many_to_many,
  AttributeTypesList.reference_many_to_one_multiple_entity_types,
  AttributeTypesList.reference_one_to_one_reversed,
  AttributeTypesList.reference_many_to_many_reversed,
  AttributeTypesList.reference_one_to_many_multiple_types,
  AttributeTypesList.reference_many_to_one_multiple_types,
  AttributeTypesList.reference_one_to_many,
  AttributeTypesList.reference_many_to_one_hierarchical,
]

export type OneOfAttributeTypes = keyof typeof AttributeTypesList
