// Core
import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
// Components
import { TextField } from 'core/form/components'
import { AdditionalOptions } from '../index'
import {
  AttributeTypeSelect,
  TagInput,
  DefaultValue,
  ValidationRules,
  ReferenceFields,
  MultipleReference,
  SelectMediaSizes,
} from './components'
// Styles
import createFieldsStyles from './create-fields.styles'
import OptionsFields from './components/options-fields'
import { useTranslation } from 'react-i18next'

type TProps = {
  data?: object
  nested?: boolean
}

const CreateFields: FC<TProps> = (props) => {
  const classes = createFieldsStyles()
  const {
    values: { type },
  } = useFormikContext<any>()
  const { nested = false } = props
  const { t } = useTranslation()

  return (
    <div className={nested ? classes.rootNested : classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AttributeTypeSelect />
        </Grid>
        <ReferenceFields />
        <MultipleReference />
        <Grid item xs={6}>
          <TextField localized name="name" label={`${t('labels.name')} *`} />
        </Grid>
        <Grid item xs={6}>
          <TextField name="slug" label={`${t('labels.slug')} *`} />
        </Grid>
        <Grid item xs={12}>
          <TextField localized name="hint" label={t('labels.hint')} />
        </Grid>
        <Grid item xs={12}>
          <DefaultValue />
        </Grid>
        <Grid item xs={12}>
          <TagInput />
        </Grid>
        {type === 'image' && (
          <Grid item xs={12}>
            <SelectMediaSizes />
          </Grid>
        )}
        <Grid item xs={12}>
          <ValidationRules />
        </Grid>
        <Grid item xs={12}>
          <OptionsFields />
        </Grid>
        <Grid item xs={12}>
          <AdditionalOptions nested={nested} />
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateFields
