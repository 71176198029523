import { IFile } from 'modules/media/types'
import { Editor } from 'tinymce'

type EditorMediaLibrary = (mediaModal: any, editor: Editor) => void

export const mediaLibrary: EditorMediaLibrary = (mediaModal, editor) => {
  editor.ui.registry.addButton('mediaLibrary', {
    text: 'media library',
    icon: 'upload',
    tooltip: 'open media library',
    onAction: () => {
      mediaModal(true, (file: IFile) => {
        if (file.mimeType.includes('image')) {
          insertImage(file, editor)
        } else {
          insertFile(file, editor)
        }
      })
    },
  })
}

const insertImage = (file: IFile, editor: any) => {
  editor.insertContent(
    `<img 
      src='${file.sourceUrl}' 
      alt='${file.alt ? file.alt : ''}' 
      title='${file.title ? file.title : ''}'
      width='${file.dimensions.width ? file.dimensions.width : ''}'
      height='${file.dimensions.height ? file.dimensions.height : ''}'
      data-media='${file.id}'
      />`
  )
}

const insertFile = (file: IFile, editor: any) => {
  editor.insertContent(
    `<a href=${file.sourceUrl} title=${file.title ? file.title : ''} >${file.filename}</a>`
  )
}
