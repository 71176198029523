// Core
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
// Redux
import { deleteFile } from '../store'
// Types
import { IFileToUpload } from '../types'

type UseDeleteFile = (file: IFileToUpload) => () => void

export const useDeleteFile: UseDeleteFile = (file) => {
  const dispatch = useDispatch()

  return useCallback(() => {
    dispatch(deleteFile(file))
  }, [dispatch, file])
}
