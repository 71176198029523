import React, { FC } from 'react'
// UI
import { Typography, Paper } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

type TProps = {}

const MediaError: FC<TProps> = () => {
  return (
    <Paper style={{ padding: '70px 16px 100px', textAlign: 'center' }}>
      <Typography
        variant="h4"
        component="h4"
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        <WarningIcon style={{ marginBottom: '30', fontSize: '100', color: '#546E7A' }} />
        <span>Something went wrong</span>
      </Typography>
    </Paper>
  )
}

export default MediaError
