// Core
import React, { FC, useCallback, useMemo, useState } from 'react'
import {
  Box,
  Grid,
  Button,
  Icon,
  Tooltip,
  ButtonGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { DragDropContext, DragDropContextProps, Draggable, Droppable } from 'react-beautiful-dnd'
// Components
import { Container, DraggableItem, WidgetTypesModal } from './components'
// Hooks
import { usePageBuilderContext } from '../../context'
import { useActionWithConfirmation } from 'modules/new-entity/hooks'
import { useSitesContext } from 'modules/sites'
// Utils
import clsx from 'clsx'
import { dragEndEvent } from 'common/utils/custom-events'
import { needConfirmation } from 'modules/new-entity/utils/static-layout-confirmation'
// Types
import { DndTypes, IEntityWidget, THEME_TWO_COLOR_SCHEME } from '../../types'
// Styles
import useStyles from './page-builder.styles'

type TypesModalState = {
  container: string
  type: 'push' | 'unshift'
}

type Props = {
  submittedOnce?: boolean
  disabled?: boolean
}

const PageBuilder: FC<Props> = ({ submittedOnce, disabled }) => {
  const classes = useStyles()
  const {
    widgets: entityWidgets,
    sections,
    dynamicLayout,
    actions: {
      addWidget,
      reorderWidgets,
      moveWidget,
      addSection,
      reorderSection,
      toggleDynamicLayout,
    },
  } = usePageBuilderContext()

  const [typesModalState, setTypesModalState] = useState<TypesModalState | null>(null)
  const [draggableBtnIndex, setDraggableBtnIndex] = useState<number | null>(null)

  const widgets = useMemo<{ [containerId: string]: IEntityWidget[] }>(() => {
    if (sections.length === 0) return {}

    const containersSchema: { [containerId: string]: IEntityWidget[] } = {}
    sections.forEach((section) => {
      section.containers.forEach((container) => {
        containersSchema[`${container.id}`] = []
      })
    })

    entityWidgets.forEach((item: IEntityWidget, index) => {
      if (containersSchema[`${item.options.container}`]) {
        containersSchema[`${item.options.container}`].push({ ...item, realIndex: index })
      }
    })

    entityWidgets.forEach((item: IEntityWidget) => {
      if (containersSchema[`${item.options.container}`]?.length) {
        containersSchema[`${item.options.container}`].sort((a: IEntityWidget, b: IEntityWidget) => {
          return a.sortOrder > b.sortOrder ? 1 : -1
        })
      }
    })

    return containersSchema
  }, [entityWidgets, sections])

  const addWidgetHandler = useCallback(
    (widgetType: any) => {
      if (!typesModalState) return
      addWidget(typesModalState.container, widgetType, typesModalState.type)
      setTypesModalState(null)
    },
    [addWidget, typesModalState]
  )

  const dragStart: DragDropContextProps['onDragStart'] = useCallback((initial) => {
    const {
      type,
      source: { index },
    } = initial

    if (type === DndTypes.section) {
      setDraggableBtnIndex(index)
    }
  }, [])

  const dragEnd: DragDropContextProps['onDragEnd'] = useCallback(
    (result) => {
      const { destination, source, type } = result
      if (!destination) return

      if (type === DndTypes.widget) {
        const dropId: any = source.droppableId
        const destId: any = destination?.droppableId

        if (dropId === destId) {
          reorderWidgets(dropId, source.index, destination?.index)
        } else {
          moveWidget(widgets[dropId], widgets[destId], source, destination)
        }
      } else {
        const dropIndex = source.index
        const destIndex = destination.index

        if (dropIndex !== destIndex) {
          reorderSection(dropIndex, destIndex)
        }

        setDraggableBtnIndex(null)
      }

      document.dispatchEvent(dragEndEvent)
    },
    [reorderWidgets, moveWidget, widgets, reorderSection]
  )

  const actionWithConfirmation = useActionWithConfirmation(
    'global.change-layout',
    'notify.change-layout'
  )

  const toggleDynamicLayoutHandler = useCallback(() => {
    if (dynamicLayout) {
      needConfirmation(sections, entityWidgets)
        ? actionWithConfirmation(toggleDynamicLayout)
        : toggleDynamicLayout()
    } else {
      toggleDynamicLayout()
    }
  }, [actionWithConfirmation, dynamicLayout, entityWidgets, sections, toggleDynamicLayout])

  const {
    siteSettings: {
      colorScheme: { value: colorSchemeValue },
    },
  } = useSitesContext()

  return (
    <Box className={classes.root}>
      {THEME_TWO_COLOR_SCHEME.includes(colorSchemeValue) && (
        <div className={classes.switcherWrap}>
          <FormControlLabel
            disabled={disabled}
            control={
              <Switch
                checked={dynamicLayout}
                onChange={toggleDynamicLayoutHandler}
                color="primary"
              />
            }
            labelPlacement="start"
            label="Dynamic layout"
          />
        </div>
      )}
      <DragDropContext onDragEnd={dragEnd} onDragStart={dragStart}>
        <Droppable droppableId="section" type={DndTypes.section}>
          {(providedDrop) => (
            <Grid container ref={providedDrop.innerRef}>
              {sections.map((section, sectionIndex) => {
                const displayControlBtn = sectionIndex > 0

                return (
                  <Draggable draggableId={section.id} index={sectionIndex} key={section.id}>
                    {(providedDrag) => (
                      <Grid container ref={providedDrag.innerRef} {...providedDrag.draggableProps}>
                        {displayControlBtn && dynamicLayout && (
                          <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                            className={clsx(classes.addSection, {
                              [classes.hidden]: draggableBtnIndex === sectionIndex,
                            })}
                          >
                            <Button
                              onClick={() => {
                                addSection(sectionIndex)
                              }}
                              className={classes.buttonControl}
                              color="primary"
                              variant="outlined"
                              disabled={disabled}
                            >
                              <Tooltip placement={'top'} title="Add container">
                                <Icon className="icon-plus-circle" />
                              </Tooltip>
                            </Button>
                          </ButtonGroup>
                        )}
                        <Grid key={section.id} item xs={12}>
                          {section.containers.map((container, containerIndex) => {
                            return (
                              <Container
                                key={`${container.id}-${containerIndex}`}
                                setTypesModalState={setTypesModalState}
                                container={container}
                                widgets={widgets[container.id]}
                                hasWidget={Boolean(widgets[container.id]?.length)}
                                sectionId={section.id}
                                sectionBg={section.options.background}
                                disabled={disabled}
                                containerDragHandleProps={providedDrag.dragHandleProps}
                                disableMoveDown={sectionIndex >= sections.length - 1}
                                disableMoveUp={sectionIndex === 0}
                                index={sectionIndex}
                              >
                                {widgets[container.id] &&
                                  widgets[container.id].map((widget, widgetIndex: number) => {
                                    return (
                                      <DraggableItem
                                        disableMoveDown={
                                          widgets[container.id].length - 1 === widgetIndex
                                        }
                                        disableMoveUp={widgetIndex === 0}
                                        data={{ ...widget, 'container-slug': container.slug }}
                                        draggableId={`${container.id}-${widget.id}`}
                                        key={`${container.id}-${widget.id}`}
                                        index={widgetIndex}
                                        submittedOnce={submittedOnce}
                                        disabled={disabled}
                                      />
                                    )
                                  })}
                              </Container>
                            )
                          })}
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                )
              })}
              {providedDrop.placeholder}
              {dynamicLayout && (
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                  className={classes.addSection}
                >
                  <Button
                    onClick={() => {
                      addSection()
                    }}
                    className={classes.buttonControl}
                    color="primary"
                    variant="outlined"
                    disabled={disabled}
                  >
                    <Tooltip placement={'top'} title="Add container">
                      <Icon className="icon-plus-circle" />
                    </Tooltip>
                  </Button>
                </ButtonGroup>
              )}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <WidgetTypesModal
        opened={Boolean(typesModalState)}
        onClose={() => setTypesModalState(null)}
        onAddWidget={addWidgetHandler}
      />
    </Box>
  )
}

export default PageBuilder
