// Core
import React from 'react'
import { ColumnType } from '../types'
import { ActionBase, DeleteAction, EditAction } from '../components'

export enum DefaultActionColumns {
  DELETE,
  EDIT,
}

type ColumnAction =
  | {
      icon: any
      onClick: (rowData: any) => void
    }
  | DefaultActionColumns

type ActionsParams = ColumnAction[]

export const getActions = (params: ActionsParams, t: any): any => {
  const column: ColumnType = {
    title: t('table.actions'),
    sorting: false,
    width: '1px',
  }

  column.render = (rowData: any) => {
    const actions = params.map((action, index) => {
      if (typeof action === 'object') {
        return (
          <ActionBase
            key={`action-${index}`}
            icon={action.icon}
            rowData={rowData}
            onClick={action.onClick}
          />
        )
      }
      switch (action) {
        case DefaultActionColumns.DELETE: {
          return <DeleteAction key={`action-${index}`} rowData={rowData} />
        }
        case DefaultActionColumns.EDIT: {
          return <EditAction key={`action-${index}`} rowData={rowData} />
        }
        default:
          return null
      }
    })

    return <div style={{ display: 'flex' }}>{actions}</div>
  }

  return column
}
