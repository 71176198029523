// Core
import React, { FC, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
// Components
import RelationsSelect from 'ui/relations-select'
// Hooks
import useFieldController from 'core/form/hooks/use-field-controller'
// Services/Utils
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
// Types
import { AttributeData } from 'modules/new-entity/types'

type Props = {
  name: string
  children?: never
  data: AttributeData
  disabled?: boolean
  required?: boolean
}

const MultipleTypeReference: FC<Props> = (props) => {
  const { name, data, disabled, required } = props
  const { entityTypes, name: label } = data
  const { t } = useTranslation()

  const [field, meta, helpers] = useFieldController(name)
  const [entityType, setEntityType] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (Boolean(field.value) && !entityType) {
      const fetchType = async () => {
        const entityId = getIdFromIri(field.value)
        const response = await httpService.get<any>(`/entities/${entityId}`)
        return response.data.entityType
      }

      setIsLoading(true)

      fetchType().then((type) => {
        setEntityType(type)
        setIsLoading(false)
      })
    }
  }, [entityType, field.value])

  const typeChangeHandler = (type: string) => {
    setEntityType(type)
    helpers.setValue('')
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RelationsSelect
          source="entity_types"
          reqParams={{ id: entityTypes }}
          label={label}
          value={entityType}
          valueField="@id"
          // @ts-expect-error
          onChange={typeChangeHandler}
          disabled={disabled || isLoading}
          required={required}
          helperText={!entityType && meta.errorMsg}
          error={!entityType && meta.hasError}
        />
      </Grid>
      <Grid item xs={6}>
        {entityType && (
          <RelationsSelect
            source="entities"
            reqParams={{ entityType }}
            helperText={meta.errorMsg}
            error={meta.hasError}
            valueField="@id"
            value={field.value}
            onChange={(val) => {
              helpers.setValue(val)
            }}
            onBlur={field.onBlur}
            label={t('select.entity')}
            entityTypeIri={entityType}
            disabled={disabled || isLoading}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default MultipleTypeReference
