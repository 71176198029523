// Core
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Button, DialogContent, Paper, Typography } from '@material-ui/core'
import clsx from 'clsx'
// Components
import { DropArea, FileList } from '../../../upload-files-modal/components'
import { useTranslation } from 'react-i18next'
// Store
import FoldersSelector from '../../../folders-selector'
import { getFolders } from 'modules/media/store'
// Hooks
import { useMediaManagerModalClose, useUploadFiles } from 'modules/media/hooks'
import { useGetOne } from 'core/data'
// Styles
import useStyles from './upload-files.styles'

export const UploadFiles = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { filesList, loading, dropzoneProps, folder, changeFolder } = useUploadFiles()
  const managerModal = useMediaManagerModalClose()
  const { t } = useTranslation()
  // @ts-ignore
  const { data } = useGetOne('media', filesList[0]?.mediaId)

  const closeModalHandler = useCallback(() => {
    changeFolder(undefined)
    managerModal(false, data)
  }, [changeFolder, data, managerModal])

  useEffect(() => {
    dispatch(getFolders())
  }, [dispatch])

  return (
    <Paper elevation={0} className={classes.paper}>
      <DialogContent
        className={clsx(classes.root, { 'no-files': Boolean(!filesList.length) })}
        dividers
      >
        <div className={clsx(classes.dropArea, { 'no-files': Boolean(!filesList.length) })}>
          <div className={classes.dropAreaWrap}>
            <FoldersSelector onChange={changeFolder} value={folder} />
          </div>
          <DropArea dropzoneProps={dropzoneProps} />
        </div>
        <div className={clsx(classes.filesList, { 'no-files': Boolean(!filesList.length) })}>
          <div className={classes.fileListHeader}>
            <Typography variant="h6">
              {t('media.loading')} {filesList.length} {t('media.files')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={closeModalHandler}
              disabled={loading}
            >
              Done
            </Button>
          </div>
          <FileList data={filesList} isWaitingUpload={loading} />
        </div>
      </DialogContent>
    </Paper>
  )
}
