// Core
import React, { FC, useCallback } from 'react'
import {
  Avatar,
  IconButton,
  CircularProgress,
  TableRow,
  TableCell,
  Tooltip,
  Chip,
  Icon,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
// Hooks
import { useDeleteFile } from 'modules/media/hooks'
import { httpService, useDelete } from 'core/data'
// Redux
import { toggleFileForm, cancelFileUpload } from 'modules/media/store'
// Types
import { IFileToUpload } from 'modules/media/types'
// Styles
import useStyles from './file-item.styles'

type TProps = {
  data: IFileToUpload
  isWaitingUpload: boolean
}

type Process = 'error' | 'loading' | 'loaded' | 'waiting'

const getProcessStep = (loading: boolean, loaded: boolean, error: any): Process => {
  if (error) return 'error'
  if (loading) return 'loading'
  return 'loaded'
}

const FileItem: FC<TProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { data } = props
  const deleteFile = useDeleteFile(data)
  const processStep = getProcessStep(data.loading, data.loaded, data.uploadError)

  const { mutate: remove } = useDelete('media')

  const deleteHandler = useCallback(() => {
    // @ts-ignore
    remove(data.mediaId || '')
    deleteFile()
  }, [data.mediaId, deleteFile, remove])

  const editHandler = useCallback(() => {
    httpService.get(`/media/${data.mediaId}`).then((response: any) => {
      dispatch(toggleFileForm(true, 'edit', response.data))
    })
  }, [data, dispatch])

  const cancelHandler = useCallback(() => {
    dispatch(cancelFileUpload(data))
  }, [data, dispatch])

  return (
    <TableRow>
      <TableCell>
        <div className={classes.fileName}>
          <Avatar src={data.preview} variant="rounded">
            <Icon className="icon-file" />
          </Avatar>
          <p>{data.file.name} </p>
        </div>
      </TableCell>
      <TableCell align="right" valign="middle">
        {processStep === 'loading' && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <CircularProgress variant="static" value={data.progress} size={30} />
            <IconButton onClick={cancelHandler}>
              <Icon className="icon-close" />
            </IconButton>
          </div>
        )}
        {processStep === 'loaded' && (
          <>
            <IconButton onClick={editHandler}>
              <Icon className="icon-edit-3" />
            </IconButton>
            <IconButton onClick={deleteHandler} edge="end">
              <Icon className="icon-delete" />
            </IconButton>
          </>
        )}
        {processStep === 'error' && (
          <Tooltip title={data.uploadError?.message || 'Unknown error'}>
            <Chip label="Failed" />
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  )
}

export default FileItem
