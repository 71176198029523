import React, { FC, useCallback } from 'react'
import clsx from 'clsx'
// Components
import { Icon, IconButton, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
// Hooks
import { useMediaModalOpen } from 'modules/media/hooks'
import { useGetOne } from 'core/data'
import { useTranslation } from 'react-i18next'
// Utils
import { getIdFromIri } from 'core/utils'
import { getFormatFromMime, isImageFromMime } from 'modules/media/utils'
// Styles
import useStyles from './grid-item.styles'
import { useConfirmation } from 'core/confirmation'

type TProps = {
  itemData: any
  setValue: (value: any) => void
  disabled?: boolean
}

export const GridItem: FC<TProps> = (props) => {
  const classes = useStyles()
  const { itemData, setValue, disabled } = props
  const { t } = useTranslation()
  const modalOpen = useMediaModalOpen(false)
  const { data: mediaData } = useGetOne('media', getIdFromIri(itemData))
  const isImage = mediaData && isImageFromMime(mediaData.mimeType)
  const { setConfirmation } = useConfirmation()

  const removeWithConfirmation = useCallback(
    (onSuccess: () => void) => {
      setConfirmation({
        open: true,
        title: t('global.delete'),
        content: t('notify.delete'),
        onSuccess,
      })
    },
    [setConfirmation, t]
  )

  return mediaData ? (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.preview}>
          {!disabled && (
            <>
              <IconButton
                className={clsx(classes.icons, 'delete-icon')}
                aria-label="delete"
                onClick={() => removeWithConfirmation(() => setValue(''))}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                className={clsx(classes.icons, 'edit-icon')}
                aria-label="edit"
                onClick={() => modalOpen(mediaData, () => setValue(''))}
              >
                <EditIcon />
              </IconButton>
            </>
          )}
          {isImage ? (
            <img src={mediaData?.thumbnailUrls.tile} alt="" />
          ) : (
            <div className={classes.fileIcon}>
              <div className={classes.format}>{getFormatFromMime(mediaData?.mimeType)}</div>
              <Icon className="icon-file" />
            </div>
          )}
        </div>
        <Typography>
          {mediaData?.dimensions.width}px {t('global.by')} {mediaData?.dimensions.height}px
        </Typography>
      </div>
    </div>
  ) : (
    <h1>{t('global.loading')}</h1>
  )
}
