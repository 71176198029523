import React, { FC } from 'react'
// UI
import { Grid, Paper } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

type TProps = {}

const FormSkeletonVertical: FC<TProps> = () => {
  return (
    <Grid container>
      <Paper style={{ width: '100%' }}>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: '24px 16px' }}
        >
          <Skeleton variant="rect" width={100} height={25} />
          <Skeleton variant="rect" width={80} height={36} />
        </Grid>
        <Skeleton variant="rect" width="100%" height={1} />
        <Grid item xs={12} container style={{ padding: '40px 16px 20px' }}>
          <Skeleton variant="rect" width="100%" height={190} />
          <Grid item xs={12} container style={{ paddingTop: 5 }}>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="70%" height={12} style={{ marginBottom: 5 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="70%" height={12} style={{ marginBottom: 5 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="70%" height={12} style={{ marginBottom: 5 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="70%" height={12} style={{ marginBottom: 5 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="70%" height={12} style={{ marginBottom: 5 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="70%" height={12} />
            </Grid>
          </Grid>
          <Grid item xs={12} container style={{ paddingTop: 35 }}>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="100%" height={56} style={{ marginBottom: 15 }} />
            </Grid>
            <Skeleton variant="rect" width="100%" height={1} style={{ margin: '25px 0 25px' }} />
            <Grid item xs={12} container justifyContent="flex-end">
              <Skeleton variant="rect" width={80} height={36} />
              <Skeleton variant="rect" width={80} height={36} style={{ marginLeft: 16 }} />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default FormSkeletonVertical
