// Core
import * as Yup from 'yup'
// Types
import { FormAttribute } from '../types'
import { Localization } from 'core/types'
import { AttributeTypesList } from 'common/types'

type ValidationData = {
  name: string
  type:
    | AttributeTypesList.string
    // TODO is it correct ???
    | 'number'
    | AttributeTypesList.string_l10n
    | AttributeTypesList.repeater
    | AttributeTypesList.group
    | AttributeTypesList.image
  rules: any[]
  setRepeats: FormAttribute[]
}

Yup.setLocale({
  mixed: {
    required: 'This is a required field',
  },
  string: {
    min: 'This field must be at least ${min} characters',
    max: 'This field must be at most ${max} characters',
    matches: 'This field must match the following: "${regex}"',
  },
  number: {
    min: 'This field must be greater than or equal to ${min}',
    max: 'This field must be less than or equal to ${max}',
    integer: 'This field must be an integer',
  },
  array: {
    min: 'This field must have at least ${min} items',
    max: 'This field must have less than or equal to ${max} items',
    // length: 'This must be have ${length} items',
  },
})

const generateData = (attributesData: FormAttribute[]): ValidationData[] => {
  return attributesData.map((item) => {
    const attrType = item.attribute.attributeType.type
    const { validationRules } = item.attribute

    const schema: any = {
      name: item.attribute['@id'],
      rules: [],
    }

    if (
      attrType === AttributeTypesList.slug ||
      attrType === AttributeTypesList.string ||
      attrType === AttributeTypesList.text ||
      attrType === AttributeTypesList.text_editor ||
      attrType === AttributeTypesList.reference_many_to_one ||
      attrType === AttributeTypesList.reference_many_to_one_hierarchical ||
      attrType === AttributeTypesList.reference_many_to_one_multiple_types ||
      attrType === AttributeTypesList.reference_one_to_one
    ) {
      schema.type = AttributeTypesList.string
    } else if (
      attrType === AttributeTypesList.string_l10n ||
      attrType === AttributeTypesList.text_l10n
    ) {
      schema.type = AttributeTypesList.string_l10n
    } else if (attrType === AttributeTypesList.integer || attrType === AttributeTypesList.decimal) {
      schema.type = 'number'

      if (attrType === AttributeTypesList.integer) {
        schema.rules.push({ type: 'integer', params: [] })
      }
    } else if (attrType === AttributeTypesList.date_time) {
      schema.type = 'date'
      schema.rules.push({ type: 'nullable', params: [] })
    } else if (attrType === AttributeTypesList.select) {
      schema.type = 'string'
    } else if (
      attrType === 'multi_select' ||
      attrType === AttributeTypesList.reference_many_to_many
    ) {
      schema.type = 'array'
    } else if (attrType === AttributeTypesList.repeater) {
      schema.type = 'repeater'
      schema.setRepeats = item.attribute.setAttributes
    } else if (attrType === AttributeTypesList.group) {
      schema.type = 'group'
      schema.setRepeats = item.attribute.setAttributes
    } else if (
      attrType === AttributeTypesList.attachment ||
      attrType === AttributeTypesList.image
    ) {
      schema.type = 'string'
      if (attrType === AttributeTypesList.image) {
        schema.type = 'image'
      }
    }

    Object.keys(validationRules).forEach((rule) => {
      if (rule === 'required' && validationRules.required === true)
        schema.rules.push({ type: rule, params: [] })
      if (rule === 'length') {
        if (validationRules.length?.min) {
          schema.rules.push({ type: 'min', params: [validationRules.length?.min] })
        }
        if (validationRules.length?.max) {
          schema.rules.push({ type: 'max', params: [validationRules.length?.max] })
        }
      }
      if (rule === 'range') {
        if (validationRules.range?.min) {
          schema.rules.push({ type: 'min', params: [validationRules.range?.min] })
        }
        if (validationRules.range?.max) {
          schema.rules.push({ type: 'max', params: [validationRules.range?.max] })
        }
      }
      if (rule === 'count') {
        if (validationRules.count?.min) {
          schema.rules.push({ type: 'min', params: [validationRules.count?.min] })
        }
        if (validationRules.count?.max) {
          schema.rules.push({ type: 'max', params: [validationRules.count?.max] })
        }
      }
      if (rule === 'regex' && validationRules.regex) {
        schema.rules.push({ type: 'matches', params: [validationRules.regex] })
      }
    })

    return schema
  })
}

const getRuleType = (type: string) => {
  if (
    //
    type === AttributeTypesList.string_l10n ||
    type === AttributeTypesList.image
  )
    return 'string'
  if (type === AttributeTypesList.repeater) return 'array'
  if (type === AttributeTypesList.group) return 'object'
  return type
}

export const generateValidationSchema = (
  attributesData: FormAttribute[],
  localizations: Localization[]
) => {
  const schema: any = {}
  const validationData = generateData(attributesData)

  validationData.forEach((ruleData) => {
    const ruleType = getRuleType(ruleData.type)

    if (!(Yup as any)[ruleType]) return

    let validator = (Yup as any)[ruleType]()

    ruleData.rules.forEach((rule) => {
      if (!validator[rule.type]) return false
      validator = validator[rule.type](...rule.params)
    })

    if (ruleData.type === AttributeTypesList.string_l10n) {
      let translationsSchema: any = schema.translations || Yup.object().shape({})

      localizations.forEach((locale) => {
        const localeSchema = Yup.object().shape({
          [locale.code]: Yup.object().shape({
            [ruleData.name]: Yup.object().shape({
              value: validator,
            }),
          }),
        })

        translationsSchema = translationsSchema.concat(localeSchema)
      })

      schema.translations = translationsSchema
    } else if (
      ruleData.type === AttributeTypesList.repeater ||
      ruleData.type === AttributeTypesList.group
    ) {
      const nestedAttributesSchema = generateValidationSchema(
        ruleData.setRepeats || [],
        localizations
      )
      if (ruleData.type === AttributeTypesList.group) {
        schema[ruleData.name] = Yup.object().shape({
          value: nestedAttributesSchema,
        })
        return
      }
      schema[ruleData.name] = Yup.object().shape({
        value: validator.of(nestedAttributesSchema),
      })
    } else {
      schema[ruleData.name] = Yup.object().shape({
        value: validator,
      })
    }
  })

  return Yup.object().shape(schema)
}
