import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { onFileSelectSelector, toggleManagerModal } from 'modules/media/store'

export type MediaManagerModalClose = () => (isOpen: boolean, selected?: any) => void

export const useMediaManagerModalClose: MediaManagerModalClose = () => {
  const onFileSelectCallback = useSelector(onFileSelectSelector)
  const dispatch = useDispatch()

  return useCallback(
    (isOpen, selected) => {
      dispatch(toggleManagerModal(false))
      if (onFileSelectCallback && selected) onFileSelectCallback(selected)
    },
    [dispatch, onFileSelectCallback]
  )
}
