import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'inherit',
      padding: '0',
      paddingLeft: theme.spacing(1),
      cursor: 'pointer',
    },
    listRoot: {
      padding: 0,
    },
  })
)

export default styles
