// Core
import React, { FC, useCallback } from 'react'
import { AppBar, Dialog, IconButton, Toolbar, Tab, Typography } from '@material-ui/core'
import { TabContext } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
// Store
import { useSelector } from 'react-redux'
import { mediaManagerOpenSelector } from '../../store'
// Components
import { TabList, TabPanel } from 'ui'
import { MediaLibrary, UploadFiles } from './components'
// Hooks
import { useTabSetValue } from '../../../types-crud/hooks/tabs/use-tab-set-value'
import { useTranslation } from 'react-i18next'
import { useMediaManagerModalClose, useUploadFiles } from '../../hooks'
// Styles
import useStyles from './media-manger-modal.styles'

type TProps = {
  data?: object
}

const MediaManagerModal: FC<TProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { tabValue, handleChange } = useTabSetValue()
  const modalIsOpen = useSelector(mediaManagerOpenSelector)
  const { changeFolder } = useUploadFiles()
  const managerModal = useMediaManagerModalClose()

  const closeModalHandler = useCallback(() => {
    changeFolder(undefined)
    managerModal(false)
  }, [changeFolder, managerModal])

  const tabChangeHandler = useCallback(
    (e: any, value: string) => {
      handleChange(e, value)
    },
    [handleChange]
  )

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={modalIsOpen}
      onClose={closeModalHandler}
      className={classes.root}
      classes={{
        paperScrollPaper: classes.custom,
      }}
    >
      <TabContext value={tabValue}>
        <AppBar color="default" position="sticky" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Typography variant="h6">{t('media.select-image')}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              className={classes.closeBtn}
              onClick={closeModalHandler}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <TabList onChange={tabChangeHandler}>
            <Tab label={t('media.upload-files')} value="tab-1" />
            <Tab label={t('media.m-library')} value="tab-2" />
          </TabList>
        </AppBar>

        <TabPanel value={tabValue} index="tab-1">
          <UploadFiles />
        </TabPanel>

        <TabPanel unmountOnChange value={tabValue} index="tab-2">
          <MediaLibrary />
        </TabPanel>
      </TabContext>
    </Dialog>
  )
}

export default MediaManagerModal
