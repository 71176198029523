import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { EntityType } from 'core/types'
import { getIdFromIri } from 'core/utils'
import QuickMutate from './quick-create'
import { useTypePermissions } from 'modules/new-entity/hooks'

type Actions = {
  createHandler?: () => void
  editHandler?: (id: number) => void
  deleteHandler?: (id: number) => void
  singleEditHandler?: (id: number) => void
  getViewPath?: (id: number) => string
  canDelete?: boolean
}

type Params = {
  entityTypeIri: string | undefined
  refetch: () => void
  onCreate?: (data: any) => void
}

export const useActions = ({ entityTypeIri, refetch, onCreate }: Params) => {
  const [crateOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState<number | null>(null)

  const entityTypeId = getIdFromIri(entityTypeIri!)

  const entityTypeQ = useQuery(
    `et-${entityTypeIri}`,
    () => {
      if (!entityTypeIri) {
        throw new Error('Id not exist')
      }

      return httpService.get<EntityType>(`/entity_types/${entityTypeId}`).then((res) => res.data)
    },
    {
      enabled: Boolean(entityTypeIri),
    }
  )

  // @ts-ignore
  const permissions = useTypePermissions(entityTypeQ?.data)

  const actions = useMemo(() => {
    const hasActions = !entityTypeId || !entityTypeQ.data?.urlable
    const actions: Actions = {}

    if (permissions.canView) {
      actions.getViewPath = (id: number) => `/entities/${entityTypeId}/edit/${id}`
    }

    if (!hasActions) return actions

    if (permissions.canCreate) {
      actions.createHandler = () => setCreateOpen(true)
    }

    if (permissions.canEdit) {
      actions.editHandler = (id: number) => setEditOpen(id)
    }

    if (permissions.canDelete) {
      actions.canDelete = permissions.canDelete
    }

    return actions
  }, [entityTypeQ.data, permissions, entityTypeId])

  const entityModal = useMemo(() => {
    if (!entityTypeIri) return null

    const isOpen = crateOpen || Boolean(editOpen)
    const isEdit = Boolean(editOpen)

    if (isOpen) {
      return (
        <QuickMutate
          entityId={editOpen as number}
          isOpen={isOpen}
          setIsOpen={() => {
            setCreateOpen(false)
            setEditOpen(null)
          }}
          entityTypeIri={entityTypeIri}
          isEdit={isEdit}
          onSuccess={(data) => {
            if (!isEdit) {
              onCreate?.(data)
            }
            refetch()
          }}
        />
      )
    }
    return null
  }, [entityTypeIri, crateOpen, editOpen, refetch, onCreate])

  return { actions, entityModal }
}
