import produce from 'immer'
import { ActionTypes, IMediaFolderState } from './types'
import { MediaFolderActionTypes } from './actions'
import { addFolder, normalizeFolderData, removeFolder, replaceFolder } from './utils'

const initialState: IMediaFolderState = {
  data: {},
  folderTree: [],
  selectedFolder: 'allFiles',
  expanded: ['root'],
  formModal: {
    open: false,
    data: null,
    type: 'create',
  },
}

const reducer = (state: IMediaFolderState = initialState, action: MediaFolderActionTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.GET_FOLDERS_SUCCESS: {
        normalizeFolderData(draft, action.payload.data)
        break
      }
      case ActionTypes.SELECT_FOLDER: {
        draft.selectedFolder = action.payload.id
        break
      }
      case ActionTypes.TOGGLE_FOLDER_EXPAND: {
        const selectedIndex = draft.expanded.findIndex((item) => item === action.payload.id)
        if (action.payload.id === 'root' && selectedIndex >= 0) {
          draft.expanded = []
          return
        }

        if (selectedIndex >= 0) {
          draft.expanded.splice(selectedIndex, 1)
        } else {
          draft.expanded.push(action.payload.id)
        }
        break
      }
      case ActionTypes.TOGGLE_FORM_MODAL: {
        draft.formModal = { ...draft.formModal, ...action.payload }
        break
      }
      case ActionTypes.REMOVE_FOLDER_SUCCESS: {
        removeFolder(draft, action.payload.id)
        break
      }
      case ActionTypes.SAVE_FOLDER_SUCCESS: {
        const { type, data } = action.payload

        if (type === 'create') addFolder(draft, data)

        if (type === 'edit') {
          replaceFolder(draft, data)
          draft.data[data.id] = data
        }
      }
    }
  })

export default reducer
