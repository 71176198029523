// Core
import React, { FC, useCallback } from 'react'
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { TextField } from '../../../../core/form/components'
import { useDispatch, useSelector } from 'react-redux'
import { folderFormModalSelector, saveFolder, toggleModalForm } from '../../store/folder'
import { transformLocalizations } from 'core/data'
import { DialogTitle } from 'ui'
import { FormLang, LanguageProvider } from 'core/data'
import FoldersSelector from '../folders-selector'
import { useTranslation } from 'react-i18next'

type TProps = {
  children?: never
}

const FolderFormModal: FC<TProps> = () => {
  const { open, type, data } = useSelector(folderFormModalSelector)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const closeHandler = useCallback(() => {
    dispatch(toggleModalForm(false, 'create'))
  }, [dispatch])

  const submitHandler = useCallback(
    (values: any) => {
      dispatch(saveFolder(values, type))
    },
    [dispatch, type]
  )

  return (
    <LanguageProvider>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={closeHandler}>
        <DialogTitle onClose={closeHandler}>
          {t(`global.${type}`)} {t(`media.folder`).toLowerCase()}
        </DialogTitle>
        <Formik
          initialValues={transformLocalizations(data) || {}}
          onSubmit={submitHandler}
          enableReinitialize
        >
          {() => (
            <Form>
              <FormLang />
              <DialogContent>
                <TextField name="name" placeholder={t('media.folder-name')} localized />
                <FoldersSelector />
              </DialogContent>
              <DialogActions>
                <Button type="submit">{t(`global.${type}`)}</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </LanguageProvider>
  )
}

export default FolderFormModal
