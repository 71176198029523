// TODO: Provider issue
import React, { FC } from 'react'
import MainForm from './components/main-form'
import { useInitialLocalization } from 'core/data'

type TProps = {
  data?: object
  onAttributeCreate?: (attrIrir: string) => void
  setRef: (node: any) => void
}

const AttributeCreate: FC<TProps> = (props) => {
  const addInitialLocalization = useInitialLocalization(['name', 'hint'])
  const initialProps = {
    attributeGroups: [],
    attributeType: '',
    attribute_groups_cache: [],
    options: {
      fieldWidth: 12,
    },
    defaultValues: {
      value: '',
    },
    attributeEnums: [],
    slug: '',
    type: '',
  }

  addInitialLocalization(initialProps)

  const { onAttributeCreate, setRef } = props

  return (
    // <ListDataProvider resource={resource}>
    <MainForm
      initialData={initialProps}
      setRef={setRef}
      setCreatedAttributeAction={onAttributeCreate}
      id="create-attribute-form"
    />
    // </ListDataProvider>
  )
}

export default AttributeCreate
