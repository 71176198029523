// Core
import React, { FC } from 'react'
import filesize from 'filesize'
// Types
import { IFile } from 'modules/media/types'

type Props = {
  rowData: IFile
}

const SizeColumn: FC<Props> = (props) => {
  const { rowData } = props

  return <div>{filesize(rowData.size)}</div>
}

export default SizeColumn
