import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    item: {
      fontSize: 12,
      wordBreak: 'break-all',
    },
    title: {
      fontSize: 14,
      fontWeight: 600,
      color: '#546E7A',
    },
  })
)

export default styles
