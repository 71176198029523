import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      '& .MuiButton-root': {
        marginRight: theme.spacing(1),
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
  })
)

export default styles
