// Core
import React, { FC, memo } from 'react'
import { AttachFile } from '@material-ui/icons'
// Types
import { IFile } from 'modules/media/types'
// Styles
import useStyles from './name-column.style'

type Props = {
  rowData: IFile
}

const NameColumn: FC<Props> = (props) => {
  const { rowData } = props
  const classes = useStyles()
  const hasThumb = !!rowData.thumbnailUrls.list

  return (
    <div className={classes.root}>
      {hasThumb ? (
        <div className={classes.fileIcon}>
          <img src={rowData.thumbnailUrls.list} alt={rowData.name} />
        </div>
      ) : (
        <div className={classes.fileIcon}>
          <AttachFile />
        </div>
      )}
      <div>
        <p className={classes.fileName} title={rowData.filename}>
          {rowData.name}
        </p>
      </div>
    </div>
  )
}

export default memo(NameColumn)
