// Core
import React, { FC } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

type Props = {
  hasError?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(2, 2, 3, 2),
    borderRadius: theme.spacing(0.5),
  },
  error: {
    borderColor: theme.palette.error.main,
  },
}))

const GroupWrapper: FC<Props> = ({ children, hasError }) => {
  const classes = useStyles()
  return <Box className={clsx(classes.root, { [classes.error]: hasError })}>{children}</Box>
}

export default GroupWrapper
