import React, { FC, useCallback, useMemo } from 'react'
import ReactHtmlParser from 'react-html-parser'
// Formik Components
import { Form, Formik, useFormikContext, Field } from 'formik'
// Custom Components
import { ConditionValuesField, SelectAttribute } from './components'
// Styles
import useStyles from './options-modal.styles'
// Material Components
import CloseIcon from '@material-ui/icons/Close'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core'
// Utils
import { avaiableNameable, fieldWidthData } from './utils'
// Components
import { SelectField } from 'core/components'
import { SwitcherField, TextField } from 'core/form'
// UI Components
import { GroupLine } from './ui'
// Types
import { Attribute } from 'core/types/attribute'
// Utils
import { setPreviewValues } from 'common/utils/attributes-options'
// Hooks
import { useGetData } from 'common/hooks/attributes-options'
import { DialogTitle } from 'ui'
import { useTranslation } from 'react-i18next'

type TProps = {
  isOpen: boolean
  attrData: Attribute
  openHandler: () => any
  isNested?: boolean
  parentData?: boolean
  pathToAttribute?: string
}

interface IInitType {
  dependsOn: string
  conditionValue: string
  fieldWidth: number
  nameable?: boolean
  hidden: boolean
  showAsColumn: boolean
  columnOrder: number
}

const initValues: IInitType = {
  dependsOn: '',
  conditionValue: '',
  fieldWidth: 12,
  nameable: false,
  hidden: false,
  showAsColumn: false,
  columnOrder: 0,
}

const ConditionalLogic: FC<TProps> = (props) => {
  const { t } = useTranslation()
  const widths = useMemo(() => fieldWidthData(t), [t])
  const { attrData, isOpen, openHandler, isNested, parentData, pathToAttribute = '' } = props
  const classes = useStyles()
  const isAvaiableNameable: boolean = avaiableNameable.includes(attrData.attributeType.type)
  const { values, setFieldValue } = useFormikContext<any>()
  const { options } = values

  const { newData, filteredData } = useGetData(attrData, parentData, isNested)
  const setOptionsPreview = setPreviewValues(pathToAttribute, options, newData)

  const removeConditiionHahdler = (formProps: any) => () => {
    formProps.setFieldValue('dependsOn', '')
  }

  const nameableSwitcher = (props: any) => {
    return (
      <FormControlLabel
        control={<Switch {...props} checked={props.value} />}
        label={t('attrs.as-title')}
      />
    )
  }

  const hiddenSwitcher = (props: any) => {
    return (
      <FormControlLabel
        control={<Switch {...props} checked={props.value} />}
        label={t('attrs.hide-attr')}
      />
    )
  }

  const saveHandler = useCallback(
    (_values: any) => {
      const oldOptions = options ? { ...options.attributeOptions } : null

      // set options
      setFieldValue('options', {
        attributeOptions: {
          ...oldOptions,
          [pathToAttribute]: {
            condition: {
              dependsOn: _values.dependsOn,
              condition: 'equal',
              conditionValue: _values.conditionValue,
            },
            fieldWidth: _values.fieldWidth,
            hidden: _values.hidden,
          },
        },
      })

      // set nameable value
      const attrData = [...newData]
      const findIndex = attrData.findIndex((element: any) => element.attribute === pathToAttribute)
      if (findIndex !== -1) {
        attrData[findIndex].nameable = _values.nameable
        attrData[findIndex].showAsColumn = _values.showAsColumn
        attrData[findIndex].columnOrder = _values.columnOrder
      }

      openHandler()
    },
    [options, setFieldValue, pathToAttribute, newData, openHandler]
  )

  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={openHandler}>
      <Formik
        initialValues={Object.keys(setOptionsPreview).length ? setOptionsPreview : initValues}
        onSubmit={saveHandler}
      >
        {(formProps: any) => (
          <Form className={classes.root}>
            <DialogTitle onClose={openHandler}>
              {t('attrs.name')}: {attrData.name}
            </DialogTitle>
            <DialogContent dividers>
              <GroupLine>
                {filteredData && filteredData.length ? (
                  <>
                    <Typography variant="body2">
                      {ReactHtmlParser(
                        t('condition.show-if', {
                          name: `<strong>${attrData.name}</strong>`,
                          interpolation: { escapeValue: false },
                        })
                      )}
                    </Typography>
                    <SelectAttribute data={filteredData} formProps={formProps} />
                    <Typography variant="body2">
                      {ReactHtmlParser(
                        t('condition.is-to', {
                          name: `<strong>${'equal'}</strong>`,
                          interpolation: { escapeValue: false },
                        })
                      )}
                    </Typography>

                    {formProps.values.dependsOn.length ? (
                      <>
                        <ConditionValuesField
                          dependsValue={formProps.values.dependsOn}
                          conditionValue={formProps.values.conditionValue}
                        />
                        <div style={{ padding: '20px' }}>
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={removeConditiionHahdler(formProps)}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </>
                    ) : (
                      <div style={{ width: '100%' }}>{t('attrs.choose')}</div>
                    )}
                  </>
                ) : (
                  t('attrs.no')
                )}
              </GroupLine>

              <GroupLine>
                <SelectField
                  data={widths}
                  name="fieldWidth"
                  label={t('attrs.field-width')}
                  value={formProps.values.fieldWidth}
                />
              </GroupLine>
              <GroupLine>
                <Grid container alignItems="center">
                  <Grid xs={2}>
                    <SwitcherField
                      isChecked={formProps.values.showAsColumn}
                      name="showAsColumn"
                      label={t('attrs.show-as-column')}
                    />
                  </Grid>
                  <Grid xs={2}>
                    <TextField label={t('attrs.column-order')} name="columnOrder" type="number" />
                  </Grid>
                </Grid>
              </GroupLine>
              <GroupLine>
                <Field
                  name="hidden"
                  as={hiddenSwitcher}
                  placeholder={t('attrs.hide-attr')}
                  value={formProps.values.hidden || false}
                />
              </GroupLine>

              {!isNested && isAvaiableNameable && (
                <GroupLine>
                  <Field
                    name="nameable"
                    as={nameableSwitcher}
                    placeholder={t('attrs.as-title')}
                    value={formProps.values.nameable}
                  />
                </GroupLine>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginLeft: 'auto' }}
              >
                {t('attrs.set-option')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default ConditionalLogic
