import React, { createContext, FC, useCallback, useContext, useEffect, useRef } from 'react'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { httpService } from 'core/data'
import { ApiResponse } from 'core/types'
import { useAppContext } from 'core/app'

type EventsContextProps = {
  subscribe: (event: string) => EventSourcePolyfill
  push: (event: string, data?: any) => Promise<unknown>
}

export type Event = {
  data: string
}

const EventsContext = createContext<EventsContextProps>(null!)

type EventsCredentials = {
  hub_url: string
  token: string
  expiresAt: string
}

export const useEvents = () => {
  return useContext(EventsContext)
}

export const EventsProvider: FC = ({ children }) => {
  const { user } = useAppContext()
  const credentials = useRef<EventsCredentials>()

  useEffect(() => {
    if (!user) return

    const init = async () => {
      const credentialsData = await httpService
        .post<ApiResponse<EventsCredentials>>('/auth_hub', {
          subscribe: ['*'],
          publish: ['*'],
          ttl: 1 * 60 * 60,
        })
        .then((res) => res.data)

      credentials.current = credentialsData
    }

    init()
  }, [user])

  const subscribe = useCallback((event: string) => {
    if (!credentials.current) {
      throw new Error('Error in auth to events hub')
    }

    const url = `${credentials.current.hub_url}?topic=${encodeURIComponent(event)}`

    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${credentials.current.token}`,
      },
      heartbeatTimeout: 100_000,
    })

    return eventSource
  }, [])

  const push = useCallback((event: string, data: any = {}) => {
    if (!credentials.current) {
      throw new Error('Error in auth to events hub')
    }

    const url = `${credentials.current.hub_url}`
    const bodyData = new URLSearchParams({ topic: event, data: JSON.stringify(data) })

    return fetch(url, {
      method: 'POST',
      body: bodyData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${credentials.current.token}`,
      },
    })
  }, [])

  return <EventsContext.Provider value={{ subscribe, push }}>{children}</EventsContext.Provider>
}
