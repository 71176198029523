import produce from 'immer'
import { ActionTypes, IFileManagerListState } from './types'
import { FileManagerListActionsTypes } from './actions'

const initialState: IFileManagerListState = {
  list: [],
  loading: false,
  params: {
    page: 1,
  },
  total: 0,
  formModal: {
    open: false,
    data: undefined,
    type: 'create',
  },
}

const reducer = (
  state: IFileManagerListState = initialState,
  action: FileManagerListActionsTypes
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.FETCH_LIST: {
        const { params, withLoading } = action.payload

        if (withLoading) {
          draft.loading = true
        }

        if (params) {
          draft.params = { ...draft.params, ...action.payload.params }
        }
        break
      }
      case ActionTypes.FETCH_LIST_SUCCESS: {
        const { data, total } = action.payload

        draft.list = data
        draft.total = total
        draft.loading = false
        break
      }
      case ActionTypes.DELETE_FILE_SUCCESS: {
        const { file } = action.payload
        const deleteIndex = draft.list.findIndex((item) => item.id === file.id)

        draft.list.splice(deleteIndex, 1)
        break
      }
      case ActionTypes.TOGGLE_FORM_MODAL: {
        draft.formModal = { ...draft.formModal, ...action.payload }
        break
      }
      case ActionTypes.UPDATE_FILE_SUCCESS: {
        const updateIndex = draft.list.findIndex((item) => item.id === action.payload.file.id)
        draft.list[updateIndex] = action.payload.file
        break
      }
    }
  })

export default reducer
