// Core
import React, { FC } from 'react'
import { DraggableProvidedDragHandleProps, Droppable } from 'react-beautiful-dnd'
import { Box, ButtonGroup, Button, Grid, Icon, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'
// Hooks
import { usePageBuilderContext } from '../../../../context'
import { useActionWithConfirmation } from 'modules/new-entity/hooks'
// Components
import ButtonAdd from '../button-add'
import SectionBg from './components/section-colorpicker'
// Types
import { SectionBackground, SectionContainer } from 'core/types/layouts'
import { DndTypes, IEntityWidget } from 'modules/new-entity/types'
import { RemoveSectionId } from '../../../../types'
// Styles
import useStyles from './container.styles'

type Props = {
  setTypesModalState: (value: any) => void
  container: SectionContainer
  widgets: IEntityWidget[]
  hasWidget: boolean
  sectionId: any
  sectionBg: string | undefined
  containerDragHandleProps: DraggableProvidedDragHandleProps | undefined
  disableMoveDown: boolean
  disableMoveUp: boolean
  index: number
  disabled?: boolean
}

const Container: FC<Props> = (props) => {
  const classes = useStyles()
  const {
    setTypesModalState,
    container,
    widgets,
    hasWidget,
    sectionId,
    sectionBg,
    children,
    containerDragHandleProps,
    disableMoveDown,
    disableMoveUp,
    index,
    disabled,
    ...rest
  } = props

  const {
    activeWidgets,
    dynamicLayout,
    actions: { toggleContainer, removeSection, reorderSection, selectSectiontBg },
  } = usePageBuilderContext()
  const isOpened = Boolean(activeWidgets[container.id])

  const actionWithConfirmation = useActionWithConfirmation(
    'global.change-layout',
    'notify.delete-container'
  )

  const removeSectionHandler = (values: RemoveSectionId) => {
    actionWithConfirmation(() => removeSection(values))
  }

  const selectSectiontBgHandler = (color: SectionBackground) => {
    selectSectiontBg(sectionId, color)
  }

  return (
    <Grid {...rest} item xs={12} className={classes.root}>
      <Box className={classes.wrap}>
        <div className={classes.topPanel}>
          <div className={classes.containerName}>
            <div
              className={clsx(classes.dndButton, { [classes.hidden]: !dynamicLayout })}
              {...containerDragHandleProps}
            >
              <Icon className={'icon-dragindrop'} />
            </div>
            <Typography className={classes.title} component={'p'}>
              {container.name}
            </Typography>
          </div>
          {dynamicLayout && (
            <div className={classes.bg}>
              <SectionBg
                label="Background"
                color={sectionBg!}
                clickHandler={selectSectiontBgHandler}
              />
            </div>
          )}

          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group"
            className={clsx(classes.buttonsBox, 'buttons-box')}
          >
            {dynamicLayout && (
              <Button
                disabled={disableMoveDown || disabled}
                onClick={() => reorderSection(index, index + 1)}
              >
                <Tooltip aria-disabled={disableMoveDown} placement={'top'} title={'move-down'}>
                  <Icon className={'icon-arrow-down'} />
                </Tooltip>
              </Button>
            )}
            {dynamicLayout && (
              <Button
                disabled={disableMoveUp || disabled}
                onClick={() => reorderSection(index, index - 1)}
              >
                <Tooltip aria-disabled={disableMoveUp} placement={'top'} title={'move-up'}>
                  <Icon className={'icon-arrow-up'} />
                </Tooltip>
              </Button>
            )}
            {hasWidget && (
              <Button onClick={() => toggleContainer(container.id, widgets)}>
                <Tooltip placement={'top'} title={`${!isOpened ? 'expand' : 'collapse'}`}>
                  <Icon className={`icon-chevron-${!isOpened ? 'down' : 'up'}`} />
                </Tooltip>
              </Button>
            )}

            {dynamicLayout && (
              <Button
                onClick={() => removeSectionHandler({ containerId: container.id, sectionId })}
              >
                <Tooltip placement={'top'} title="remove container">
                  <Icon className="icon-trash" />
                </Tooltip>
              </Button>
            )}
          </ButtonGroup>
        </div>

        {hasWidget && (
          <ButtonAdd
            disabled={disabled}
            onClick={() => {
              setTypesModalState({
                container: container.id,
                type: 'unshift',
              })
            }}
            fullWidth
          />
        )}

        <div className={classes.holder}>
          <Droppable droppableId={container.id} type={DndTypes.widget}>
            {(providedDrop) => {
              return (
                <Grid innerRef={providedDrop.innerRef} container spacing={1}>
                  {children}
                  {providedDrop.placeholder}
                </Grid>
              )
            }}
          </Droppable>
        </div>

        <ButtonAdd
          disabled={disabled}
          onClick={() => {
            setTypesModalState({
              container: container.id,
              type: 'push',
            })
          }}
          fullWidth
        />
      </Box>
    </Grid>
  )
}

export default Container
