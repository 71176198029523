import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .Mui-selected': {
        backgroundColor: 'transparent',
        '& > div:first-child': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          borderRadius: theme.spacing(0.5),
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      overflow: 'auto',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      '&::-webkit-scrollbar': {
        width: 12,
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
        borderRadius: 10,
        backgroundColor: '#F5F5F5',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 10,
        boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
      },
    },
  })
)

export default styles
