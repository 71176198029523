import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { defaultThemeColors } from 'core/themes/default/default'

const widgetItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      position: 'relative',
      flexWrap: 'wrap',

      padding: theme.spacing(0, 0, 0, 0),
      border: '1px solid transparent',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)',
      borderRadius: theme.spacing(0.5),
      transition: theme.transitions.create(['border-color'], {
        duration: theme.transitions.duration.standard,
      }),
      backgroundColor: '#FFFFFF',
      '&:has(.Mui-error)': {
        borderColor: theme.palette.error.main,
      },
      '&.isPreview': {
        marginTop: 10,
      },
      '&:hover': {
        borderColor: '#5850EC',
        '& .action--area': {
          opacity: 1,
        },
      },
      '&.headerOpened': {
        padding: theme.spacing(0, 0, 4, 0),
      },
    },
    isGlobal: {
      backgroundColor: 'rgba(1, 18, 253, 0.06)',
    },

    header: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: theme.spacing(4, 4, 4, 2),

      '&.headerOpened': {
        padding: theme.spacing(4, 4, 2, 2),
      },
    },

    actionArea: {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      opacity: 0,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.standard,
      }),
      '& > div': {
        background: '#fff',
      },
    },
    buttonGroup: {
      '& button': {
        padding: '1px 8px',
      },
    },
    toggleWidget: {
      position: 'absolute',
      display: 'block',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
      cursor: 'pointer',
    },
    icons: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    dragger: {
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '-40px',
        right: '-5px',
        bottom: '-40px',
        left: '-17px',
      },
    },
    counter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,

      width: '30px',
      height: '30px',

      marginRight: theme.spacing(1),
      borderRadius: '50%',
      backgroundColor: defaultThemeColors.purpleLight,
    },
    contentHolder: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      maxWidth: '80%',
      wordBreak: 'break-word',
    },
    name: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      fontSize: '15px',
      lineHeight: '18px',
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
    },
    descr: {
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.text.primary,
    },
    selectWrap: {
      display: 'flex',
      flexWrap: 'wrap',
      // flexDirection: 'column',
    },
    widthSelect: {
      display: 'flex',
      minWidth: '240px',
      marginLeft: 'auto',
      '& .MuiOutlinedInput-input': {
        padding: '12.5px 14px',
      },
      '& .MuiInputLabel-formControl': {
        top: '-5px',
      },
      '& > *:not(:last-child)': {
        marginRight: '10px',
      },
    },
    expandPanelEdit: {
      width: '100%',
      padding: theme.spacing(0, 2, 0, 1),
    },
    expandPanel: {
      width: '100%',
    },
  })
)

export default widgetItemStyles
