// Core
import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
// Components
import VersionsSelect from './versions-select'
import VersionDialog from './version-dialog'
// Hooks
import useResourceSwitcherControl from './use-resource-switcher-controller'
// Types
import { OnVersionMutation } from '../types'

type Props = {
  resource: string
  resourceId: number
  typeId?: number
  entityType?: string
  basePath?: string
  onVersionMutation: OnVersionMutation
  onVersionMutationSuccess?: () => Promise<unknown>
  mt?: number
  disabled?: boolean
}

const ResourceVersionSwitcher: FC<Props> = (props) => {
  const { resource, resourceId, mt = 2, disabled, entityType } = props

  const {
    mutationState,
    setMutationState,
    data,
    isLoading,
    isFetching,
    versionCreateHandler,
    getEditPath,
    refetch,
  } = useResourceSwitcherControl(props)

  if (isLoading || !data) {
    return (
      <Box maxWidth={200} mt={2}>
        <Skeleton height={48} />
      </Box>
    )
  }

  const { versions, isOriginal, originalId, currentName } = data
  const options = versions || []

  return (
    <Box mt={mt}>
      <VersionsSelect
        options={options}
        label={currentName!}
        onCreate={() => setMutationState({ type: 'create' })}
        hasEdit={!isOriginal}
        onEdit={(id) => setMutationState({ type: 'edit', id })}
        getEditPath={getEditPath}
        currentId={resourceId}
        originalId={originalId}
        refreshData={refetch}
        isFetching={isFetching}
        resource={resource}
        disabled={disabled}
        entityType={entityType}
      />
      {mutationState && (
        <VersionDialog
          editId={mutationState.id}
          open={Boolean(mutationState)}
          onClose={() => setMutationState(null)}
          onVersionMutation={versionCreateHandler}
          originalId={originalId}
          resource={resource}
          versions={versions}
        />
      )}
    </Box>
  )
}

export default ResourceVersionSwitcher
