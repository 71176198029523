import { UseQueryOptions, useQuery } from 'react-query'
import { httpService } from 'core/data'
import { HydraResponse } from 'core/types'
import { EntityListItem } from '../types'
import { useState } from 'react'

type EntitiesListRes = HydraResponse<EntityListItem>

type Params = {
  entityTypeId: number
  queryOptions?: UseQueryOptions<any, any, any, any>
  search?: string
  filters?: any
}

const QUERY_KEY = 'entities-list'

export default function useEntitiesList(params: Params) {
  const { entityTypeId, queryOptions, search, filters } = params

  const [page, setPage] = useState(1)

  const query = useQuery<EntitiesListRes>(
    [QUERY_KEY, entityTypeId, page, filters],
    () => {
      const reqParams: any = {
        entityType: `/api/entity_types/${entityTypeId}`,
        page,
        search,
      }

      if (filters) {
        reqParams.filter = filters
      }

      return httpService
        .get<EntitiesListRes>(`/entities`, {
          params: reqParams,
        })
        .then((res) => res.data)
    },
    queryOptions
  )

  return {
    data: query.data?.['hydra:member'] || [],
    totalCount: query.data?.['hydra:totalItems'] || 0,
    page,
    setPage,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
  }
}
