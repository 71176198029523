import produce from 'immer'
import { ActionTypes, IUploadFilesState } from './types'
import { MediaManagerActionsTypes } from './actions'

const initialState: IUploadFilesState = {
  open: false,
  loading: false,
  filesToUpload: [],
  mediaManagerOpen: false,
}

const reducer = (state: IUploadFilesState = initialState, action: MediaManagerActionsTypes) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.TOGGLE_UPLOAD_MODAL: {
        const { value } = action.payload
        draft.open = value

        if (!value) {
          draft.loading = false
          draft.filesToUpload = []
        }
        break
      }
      case ActionTypes.SET_FILES_TO_UPLOAD: {
        const { files } = action.payload
        draft.filesToUpload.push(...files)
        break
      }
      case ActionTypes.UPLOAD_FILES: {
        draft.loading = true
        break
      }
      case ActionTypes.UPLOAD_FILES_SUCCESS: {
        return initialState
      }
      case ActionTypes.UPLOAD_FILE: {
        const { file } = action.payload
        const updateIndex = state.filesToUpload.findIndex((fileItem) => fileItem.id === file.id)
        //
        draft.loading = true
        draft.filesToUpload[updateIndex].loading = true
        break
      }
      case ActionTypes.UPLOAD_FILE_SUCCESS: {
        const { file, mediaData } = action.payload
        const updateIndex = state.filesToUpload.findIndex((fileItem) => fileItem.id === file.id)
        //
        draft.loading = false
        draft.filesToUpload[updateIndex].loading = false
        draft.filesToUpload[updateIndex].loaded = true
        draft.filesToUpload[updateIndex].mediaId = mediaData.id
        break
      }
      case ActionTypes.DELETE_FILE: {
        const { file } = action.payload
        const deleteIndex = state.filesToUpload.indexOf(file)

        draft.filesToUpload.splice(deleteIndex, 1)
        break
      }
      case ActionTypes.UPDATE_UPLOAD_PROGRESS: {
        const { file, value } = action.payload
        const updateIndex = state.filesToUpload.findIndex((fileItem) => fileItem.id === file.id)

        draft.filesToUpload[updateIndex].progress = value
        break
      }
      case ActionTypes.SET_UPLOAD_FILE_ERROR: {
        const { file, error } = action.payload
        const updateIndex = state.filesToUpload.findIndex((fileItem) => fileItem.id === file.id)
        draft.filesToUpload[updateIndex].uploadError = error
        draft.loading = false

        break
      }
      case ActionTypes.CANCEL_FILE_UPLOAD: {
        const { file } = action.payload

        file.cancelToken.cancel()

        const deleteIndex = state.filesToUpload.indexOf(file)
        draft.filesToUpload.splice(deleteIndex, 1)
        break
      }
      case ActionTypes.TOGGLE_MANAGER_MODAL: {
        const { value, onFileSelect } = action.payload
        draft.mediaManagerOpen = value
        draft.onFileSelect = onFileSelect

        if (!value) {
          draft.loading = false
          draft.filesToUpload = []
          draft.onFileSelect = undefined
        }
        break
      }
    }
  })

export default reducer
