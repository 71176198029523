import { AxiosRequestConfig } from 'axios'
import cloneDeep from 'lodash.clonedeep'
import transform from 'lodash.transform'

const recursiveTransform = (obj: any) =>
  transform(obj, (result: any, value: any, key: any) => {
    if (key === 'currentLang') return
    if (key === 'translations') {
      const locales = Object.keys(value)
      const localizations: any = []

      locales.forEach((locale) => {
        localizations.push({
          locale: `/api/localizations/${locale}`,
          ...value[locale],
        })
      })

      result.localizations = localizations
    } else {
      // TODO: refactor this moment, maybe with lodash utils or create own
      result[key] =
        (Array.isArray(value) || typeof value === 'object') && value !== null
          ? recursiveTransform(value)
          : value
    }
  })

export const transformLocalizations = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (!(config.data instanceof FormData)) {
    config.data = recursiveTransform(cloneDeep(config.data))
  }

  return config
}
