// Core
import { takeEvery, call, select, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
// Redux
import { ActionTypes, ISaveFolder } from '../types'
import { toggleModalForm, saveFolderSuccess } from '../actions'
import { selectedFolderSelector } from '../selectors'
// Services
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'

function* saveFolderWorker(action: ISaveFolder) {
  try {
    const { data, type } = action.payload
    let saveData

    const selectedFolder: string = yield select(selectedFolderSelector)
    if (type === 'create') {
      if (!data.parent && !['root', 'allFiles'].includes(selectedFolder)) {
        data.parent = `/api/media_folders/${selectedFolder}`
      } else if (data.parent && data.parent === 'root') {
        data.parent = null
      } else if (data.parent) {
        data.parent = `/api/media_folders/${data.parent}`
      }

      const createRes = yield call(httpService.post, '/media_folders', data)
      const createdId = getIdFromIri(createRes.data['@id'])
      const createdFolderRes = yield call(httpService.get, `/media_folders/${createdId}`)
      saveData = createdFolderRes.data

      toast.success('Folder created successfully')
    } else {
      if (data.parent === 'root' || data.parent === null) {
        data.parent = null
      } else {
        const isIri = data.parent.indexOf('/api') >= 0
        if (!isIri && data.parent !== 'root') data.parent = `/api/media_folders/${data.parent}`
      }

      yield call(httpService.put, `/media_folders/${data.id}`, data)
      const updatedRes = yield call(httpService.get, `/media_folders/${data.id}`)
      saveData = updatedRes.data

      toast.success('Folder updated successfully')
    }

    yield put(saveFolderSuccess(saveData, type))
    yield put(toggleModalForm(false, 'create', {}))
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export function* saveFolderWatcher() {
  yield takeEvery(ActionTypes.SAVE_FOLDER, saveFolderWorker)
}
