import {
  ActionTypes,
  IDeleteFileAction,
  IDeleteFileSuccessAction,
  IFetchListAction,
  IFetchListSuccess,
  IToggleFormModal,
  IUpdateFile,
  IUpdateFileSuccess,
} from './types'
import { IFile, ListParams } from '../../types'

export const fetchFilesList = (
  params?: ListParams,
  withLoading: boolean = true
): IFetchListAction => ({
  type: ActionTypes.FETCH_LIST,
  payload: { params, withLoading },
})

export const fetchFilesListSuccess = (data: any, total: number): IFetchListSuccess => ({
  type: ActionTypes.FETCH_LIST_SUCCESS,
  payload: { data, total },
})

export const deleteFileFromList = (file: IFile): IDeleteFileAction => ({
  type: ActionTypes.DELETE_FILE,
  payload: { file },
})

export const deleteFileFromListSuccess = (file: IFile): IDeleteFileSuccessAction => ({
  type: ActionTypes.DELETE_FILE_SUCCESS,
  payload: { file },
})

export const toggleFileForm = (
  value: boolean,
  type: 'create' | 'edit' = 'create',
  data?: any,
  onDelete?: () => void
): IToggleFormModal => ({
  type: ActionTypes.TOGGLE_FORM_MODAL,
  payload: { open: value, type, data, onDelete },
})

export const updateFile = (file: IFile): IUpdateFile => ({
  type: ActionTypes.UPDATE_FILE,
  payload: { file },
})

export const updateFileSuccess = (file: IFile): IUpdateFileSuccess => ({
  type: ActionTypes.UPDATE_FILE_SUCCESS,
  payload: { file },
})

export type FileManagerListActionsTypes =
  | IFetchListAction
  | IFetchListSuccess
  | IDeleteFileAction
  | IDeleteFileSuccessAction
  | IToggleFormModal
  | IUpdateFile
  | IUpdateFileSuccess
