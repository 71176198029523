// Core
import React, { FC } from 'react'
import { Icon } from '@material-ui/core'
import clsx from 'clsx'
// @ts-ignore
import useDimensions from 'react-use-dimensions'
import { useTranslation } from 'react-i18next'
// Styles
import useStyles from './drop-area.styles'
import { DropzoneState } from 'react-dropzone'

type TProps = {
  dropzoneProps: DropzoneState
}

const DropArea: FC<TProps> = (props) => {
  const { dropzoneProps } = props
  const { t } = useTranslation()
  const { getRootProps, getInputProps, isDragActive } = dropzoneProps
  const [ref, { width }] = useDimensions()

  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, { active: isDragActive })}
      ref={ref}
      style={{ width }}
      {...getRootProps()}
    >
      <Icon className={clsx(classes.icon, 'icon-upload')} fontSize="inherit" />
      <p className={classes.text}>{t('media.drop')}</p>
      <input {...getInputProps()} />
    </div>
  )
}

export default DropArea
