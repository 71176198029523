import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { httpService } from 'core/data'

type UseUpdateOne = (
  resource: string
) => UseMutationResult<unknown, unknown, { id: any; values: any }, number>

export const useUpdate: UseUpdateOne = (resource) => {
  const queryClient = useQueryClient()

  return useMutation(
    (props: { id: any; values: any }) => {
      const { id, values } = props
      return httpService.put(`/${resource}/${id}`, values)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(resource)
      },
    }
  )
}
