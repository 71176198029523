// Core
import React, { FC, memo } from 'react'
import { useQuery } from 'react-query'
import { LinearProgress } from '@material-ui/core'
// Types
import { IFile } from 'modules/media/types'
// Styles
import useStyles from './folder-column.style'
// Services
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'

type Props = {
  rowData: IFile
}

const FolderColumn: FC<Props> = (props) => {
  const { rowData } = props

  const classes = useStyles()

  const { data, isLoading } = useQuery(
    ['folder', rowData],
    async () => {
      if (rowData.folder === null) return { name: 'Root' }
      return httpService
        .get(`/media_folders/${getIdFromIri(rowData.folder)}`)
        .then((res: any) => res.data)
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  return (
    <div className={classes.root}>
      {isLoading && <LinearProgress color="primary" />}
      {data && <span className={classes.title}>{data.name}</span>}
    </div>
  )
}

export default memo(FolderColumn)
