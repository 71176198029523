// Core
import React, { FC } from 'react'
import { Button, Icon } from '@material-ui/core'
// Utils
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
// Types
import { ButtonProps } from '@material-ui/core/Button/Button'
// Styles
import useStyles from './button-add.styles'

type TProps = {} & ButtonProps

const ButtonAdd: FC<TProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Button {...props} className={classes.root} fullWidth>
      <Icon className={clsx('icon-plus-circle', classes.icon)} />
      {t('widget.add-widget')}
    </Button>
  )
}

export default ButtonAdd
