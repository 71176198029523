// Core
import React, { FC, useMemo } from 'react'
import { Box, TableCell } from '@material-ui/core'
import { formatInTimeZone } from 'date-fns-tz'
// Types
import { ColumnData, RowData } from './types'
import { AttributeTypesList } from 'common/types'
import { Check, Close } from '@material-ui/icons'
import { useGetActiveSite } from 'modules/sites'

type Props = {
  children?: never
  rowData: RowData
  columnData: ColumnData
}

const StringColumn: FC<{ value: string }> = ({ value }) => {
  return <span>{value}</span>
}

const BooleanColumn: FC<{ value: string }> = ({ value }) => {
  return <Box>{value ? <Check /> : <Close />}</Box>
}

const SelectColumn: FC<{ value: string; columnData: ColumnData }> = ({ value, columnData }) => {
  const selectValue = columnData.enums.find((enumItem) => enumItem.id === +value)
  if (!selectValue) return null
  return <Box>{selectValue.name}</Box>
}

const DateTimeColumn: FC<{ value: string }> = ({ value }) => {
  const site = useGetActiveSite()
  if (!site || !value) return null
  const formatDate = formatInTimeZone(
    new Date(value),
    site.timezone,
    `${site.dateFormat} ${site.timeFormat}`
  )
  return <span>{formatDate}</span>
}

const stringColumns: any = [
  AttributeTypesList.string,
  AttributeTypesList.slug,
  AttributeTypesList.integer,
  AttributeTypesList.decimal,
]

const Column: FC<Props> = (props) => {
  const { columnData, rowData } = props

  const column = useMemo(() => {
    let columnValue

    if (columnData.markedAsTitle) {
      columnValue = { value: rowData.name }
    } else if (['_createdAt', '_updatedAt'].includes(columnData.slug)) {
      const fieldName = columnData.slug.replace('_', '') as 'createdAt' | 'updatedAt'
      columnValue = { value: rowData[fieldName] }
    } else {
      columnValue = rowData.valuesMap[columnData.attrIri]
    }

    if (stringColumns.includes(columnData.attrType)) {
      if (columnData.attrOptions.show_links_modal) return <span>Not support</span>
      return <StringColumn value={columnValue?.value || ''} />
    }
    if (columnData.attrType === AttributeTypesList.boolean) {
      return <BooleanColumn value={columnValue?.value || ''} />
    }
    if (columnData.attrType === AttributeTypesList.date_time) {
      return <DateTimeColumn value={columnValue?.value || ''} />
    }
    if (columnData.attrType === AttributeTypesList.select) {
      return <SelectColumn columnData={columnData} value={columnValue?.value || ''} />
    }

    return <span>Not support</span>
  }, [columnData, rowData])

  return (
    <TableCell align="left" style={{ maxWidth: 200, wordBreak: 'break-word' }}>
      {column}
    </TableCell>
  )
}

export default Column
