// Core
import React, { FC, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
// Components
import { DialogTitle } from 'ui'
// Redux
import { removeFolder } from 'modules/media/store'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useListContext } from 'core/data'

type Props = {
  open: boolean
  onClose: () => void
  folderId: string
}

const DeleteFolderDialog: FC<Props> = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { open, onClose, folderId } = props
  const [options, setOptions] = useState('root')
  const {
    listParams: { methods },
  } = useListContext()

  const optionsChangeHandler = (e: any) => {
    e.stopPropagation()
    setOptions(e.target.value)
  }

  const handleClose = () => {
    onClose()
  }

  const deleteHandler = () => {
    dispatch(removeFolder(folderId, options, () => methods.setFilter('folder', 'null')))
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle onClose={handleClose}>{t('media.del-folder')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{t('notify.delete')}</DialogContentText>
        <FormControl component="fieldset">
          <FormLabel component="legend">{t('media.del-options')}</FormLabel>
          <RadioGroup name="delete-options" value={options} onChange={optionsChangeHandler}>
            <FormControlLabel value="root" control={<Radio />} label={t('media.replace-opt')} />
            <FormControlLabel value="force" control={<Radio />} label={t('media.all-opt')} />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteHandler} color="primary" autoFocus>
          {t('global.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteFolderDialog
