// Core
import { all, fork } from 'redux-saga/effects'
// Redux
import { mediaManagerListSaga } from './list'
import { mediaManagerUploadFilesSaga } from './upload-files'
import { mediaFolderSaga } from './folder'

// eslint-disable-next-line func-names
export default function* () {
  yield all([fork(mediaManagerListSaga), fork(mediaManagerUploadFilesSaga), fork(mediaFolderSaga)])
}
