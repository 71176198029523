import React, { FC, useCallback } from 'react'
import pick from 'lodash.pick'
import { LinearProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useGetAttributesTypes } from 'common/hooks/attribute/use-get-attributes-types'
import {
  useAttributeTypeSetValue,
  getAttrType,
} from 'common/hooks/attribute/use-attribute-type-set-value'
import { AttributeType } from 'core/types/attribute-type'
import { WordBreakWrapper } from 'ui'
import { useTranslation } from 'react-i18next'
// styles
import useStyles from './attribute-type-select.styles'

type DefaultValues = {
  defaultValues:
    | []
    | {
        value: string | [] | null | boolean
      }
}

const AttributeTypeSelect: FC = () => {
  const classes = useStyles()
  const { data, isLoading, error } = useGetAttributesTypes()
  const { values, resetForm } = useFormikContext<any>()
  const setAttributeType = useAttributeTypeSetValue()
  const { t } = useTranslation()

  const setInitialDefaultValue = useCallback((type): DefaultValues => {
    if (type === 'boolean') {
      return {
        defaultValues: { value: 'false' },
      }
    }

    if (type === 'multi_select' || type === 'reference_many_to_many') {
      return {
        defaultValues: {
          value: [],
        },
      }
    }
    if (type === 'date_time') {
      return {
        defaultValues: {
          value: null,
        },
      }
    }
    return {
      defaultValues: {
        value: '',
      },
    }
  }, [])

  const changeHandler = useCallback(
    (event: any) => {
      resetForm({
        values: {
          ...pick(values, ['translations', 'slug']),
          ...setInitialDefaultValue(getAttrType(data || [], event.target.value)),
        },
      })

      setAttributeType(event.target.value, data)
    },
    [setInitialDefaultValue, data, resetForm, values, setAttributeType]
  )

  if (isLoading) return <LinearProgress />
  if (error) return <div>Something went wrong...</div>

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="attribute_type-select">{t('labels.global-type-hint')}</InputLabel>
      <Select
        label={t('labels.global-type-hint')}
        id="attribute_type-select"
        value={values.attributeType || ''}
        defaultValue={values.attributeType || ''}
        onChange={changeHandler}
      >
        {data?.map((item: AttributeType) => {
          return (
            <MenuItem className={classes.menuItem} key={item.id} value={item['@id']}>
              <WordBreakWrapper>{t(`attr-types.${item.type}`)}</WordBreakWrapper>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default AttributeTypeSelect
