// Core
import React, {
  cloneElement,
  createElement,
  FC,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Form, Formik } from 'formik'
import { Box } from '@material-ui/core'
import { AddCircle, Save } from '@material-ui/icons'
// Components
import SidebarGlobal from 'core/components/sidebar'
import PageHeader from 'modules/layout/components/page-header'
import { SubmitButton } from 'core/form'
import ActionsPopover from 'common/components/actions-popover'
// Utils
import { transformLocalizations } from 'core/data'
// Types
import { ActionsData } from 'core/data/types/actions'

type TProps = {
  children: ReactElement
  onSubmit: (data?: any) => void
  initialProps?: any
  type: 'create' | 'edit'
  errors?: any
  sidebar?: any
  setRef?: (e: any) => void
  validationSchema?: any
  loading?: boolean
  actionsData?: ActionsData
  resourceName: string
}

const CrudFormController: FC<TProps> = (props) => {
  const {
    children,
    onSubmit,
    initialProps = {},
    errors,
    sidebar,
    type,
    setRef,
    validationSchema,
    actionsData,
    resourceName,
  } = props

  const hasSidebar = Boolean(sidebar)

  const actionsWrapperRef = useRef<HTMLDivElement | null>(null)

  const [showActions, setShowActions] = useState(false)

  const hasActions = useMemo(
    () => actionsData?.actions.some((action) => action.condition),
    [actionsData]
  )

  const onShowActions = useCallback((value: boolean) => {
    setShowActions(value)
  }, [])

  return (
    <Formik
      innerRef={setRef}
      initialValues={transformLocalizations(initialProps)}
      onSubmit={onSubmit}
      initialErrors={errors}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Box component={Form} height="100%" display="flex" flexDirection="column">
          <PageHeader
            backPath={`/${resourceName}`}
            title={`${type === 'create' ? 'Create' : 'Edit'} ${resourceName}`}
            wrapperRef={actionsWrapperRef}
            actions={
              <>
                <SubmitButton
                  title={type === 'create' ? 'Create' : 'Save'}
                  startIcon={type === 'create' ? <AddCircle /> : <Save />}
                  size="medium"
                />
                {actionsData && hasActions && (
                  <ActionsPopover
                    actions={actionsData.actions}
                    anchorEl={actionsWrapperRef.current}
                    showActions={showActions}
                    onShowActions={onShowActions}
                    loading={actionsData.loading}
                  />
                )}
              </>
            }
          />

          <Box display="flex" flex={1}>
            <Box bgcolor="common.white" flex={1} p={4}>
              {cloneElement(children, { ...formikProps, ...props })}
            </Box>
            {hasSidebar && (
              <SidebarGlobal type={type} actionsData={actionsData}>
                {sidebar ? createElement(sidebar, { ...props }) : undefined}
              </SidebarGlobal>
            )}
          </Box>
        </Box>
      )}
    </Formik>
  )
}

export default CrudFormController
