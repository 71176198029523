import {
  ActionTypes,
  ICancelFileUpload,
  IDeleteFileAction,
  IRenameFileAction,
  ISetFilesToUploadAction,
  ISetUploadFileError,
  IToggleUploadModalAction,
  IUpdateUploadProgress,
  IUploadFileAction,
  IUploadFilesAction,
  IUploadFilesSuccessAction,
  IUploadFileSuccessAction,
  IToggleManagerModal,
} from './types'
import { IFile, IFileToUpload } from 'modules/media/types'
import { FileToUpload } from 'modules/media/classes'

export const toggleUploadModal = (value: boolean): IToggleUploadModalAction => ({
  type: ActionTypes.TOGGLE_UPLOAD_MODAL,
  payload: { value },
})

export const setFilesToUpload = (
  files: IFileToUpload[],
  folder?: string
): ISetFilesToUploadAction => ({
  type: ActionTypes.SET_FILES_TO_UPLOAD,
  payload: { files, folder },
})

export const uploadFiles = (): IUploadFilesAction => ({
  type: ActionTypes.UPLOAD_FILES,
})

export const uploadFilesSuccess = (): IUploadFilesSuccessAction => ({
  type: ActionTypes.UPLOAD_FILES_SUCCESS,
})

export const uploadFile = (file: IFileToUpload): IUploadFileAction => ({
  type: ActionTypes.UPLOAD_FILE,
  payload: { file },
})

export const uploadFileSuccess = (
  file: IFileToUpload,
  mediaData: IFile
): IUploadFileSuccessAction => ({
  type: ActionTypes.UPLOAD_FILE_SUCCESS,
  payload: { file, mediaData },
})

export const deleteFile = (file: IFileToUpload): IDeleteFileAction => ({
  type: ActionTypes.DELETE_FILE,
  payload: { file },
})

export const renameFile = (file: FileToUpload, name: string): IRenameFileAction => ({
  type: ActionTypes.RENAME_FILE,
  payload: { file, name },
})

export const updateUplaodProgress = (file: FileToUpload, value: number): IUpdateUploadProgress => ({
  type: ActionTypes.UPDATE_UPLOAD_PROGRESS,
  payload: { file, value },
})

export const setUploadFileError = (file: IFileToUpload, error: any): ISetUploadFileError => ({
  type: ActionTypes.SET_UPLOAD_FILE_ERROR,
  payload: { file, error },
})

export const cancelFileUpload = (file: IFileToUpload): ICancelFileUpload => ({
  type: ActionTypes.CANCEL_FILE_UPLOAD,
  payload: { file },
})

export const toggleManagerModal = (
  value: boolean,
  onFileSelect?: (file: IFile) => void
): IToggleManagerModal => ({
  type: ActionTypes.TOGGLE_MANAGER_MODAL,
  payload: { value, onFileSelect },
})

export type MediaManagerActionsTypes =
  | IToggleUploadModalAction
  | ISetFilesToUploadAction
  | IUploadFilesAction
  | IUploadFileAction
  | IDeleteFileAction
  | IRenameFileAction
  | IUploadFileSuccessAction
  | IUploadFilesSuccessAction
  | IUpdateUploadProgress
  | ISetUploadFileError
  | ICancelFileUpload
  | IToggleManagerModal
