// Core
import React, { FC } from 'react'
import { Alert } from '@material-ui/lab'
import { Button, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    justifyContent: 'center',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    '& .MuiAlert-action': {
      marginLeft: 0,
    },
  },
})

type Props = {
  backPath: string
}

const RevisionModeAlert: FC<Props> = ({ backPath }) => {
  const classes = useStyles()

  return (
    <Alert
      severity="info"
      className={classes.root}
      action={
        <Button variant="outlined" color="primary" size="small" component={Link} to={backPath}>
          Exit history
        </Button>
      }
    >
      You are currently viewing a history version in "Read-only"
    </Alert>
  )
}

export default RevisionModeAlert
