import { createSelector, Selector } from 'reselect'
import { IRootState } from 'core/init/types'
import { IMediaFolderState } from './types'

const baseSelector: Selector<IRootState, IMediaFolderState> = (state) => state.mediaManager.folders

export const foldersSelector = createSelector(baseSelector, (state) => state.data)
export const foldersTreeSelector = createSelector(baseSelector, (state) => state.folderTree)
export const selectedFolderSelector = createSelector(baseSelector, (state) => state.selectedFolder)
export const expandedSelector = createSelector(baseSelector, (state) => state.expanded)
export const folderFormModalSelector = createSelector(baseSelector, (state) => state.formModal)
