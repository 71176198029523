import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const containerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '15px',
    },
    wrap: {
      border: '1px dashed #BDBDBD',
      borderRadius: '2px',
      padding: theme.spacing(2),
      position: 'relative',
      backgroundColor: '#FFFFFF',
      '&:hover': {
        borderColor: '#5850EC',
        borderStyle: 'solid',
        '& .buttons-box': {
          opacity: 1,
        },
      },
    },
    topPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    },
    containerName: {
      display: 'flex',
      alignItems: 'center',
    },
    dndButton: {
      marginRight: '12px',
      '& span': {
        display: 'block',
      },
    },
    hidden: {
      display: 'none',
    },
    title: {
      fontSize: '1rem',
      lineHeight: '22px',
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
    bg: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonsBox: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 2,
      opacity: 0,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.standard,
      }),
    },
    holder: {
      margin: theme.spacing(1, 0),
    },
  })
)

export default containerStyles
