// Core
import { put, race, take } from 'redux-saga/effects'
// Redux
import { ActionTypes } from './types'
import { showConfirmDialog } from './actions'

export function* showConfirm(text: string) {
  yield put(showConfirmDialog(text, true))

  const { yes } = yield race({
    no: take(ActionTypes.CANCEL),
    yes: take(ActionTypes.CONFIRM),
  })

  yield put(showConfirmDialog('', false))

  return !!yes
}
