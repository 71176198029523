import { createSelector, Selector } from 'reselect'
import { IRootState } from 'core/init/types'
import { IUploadFilesState } from './types'

const baseSelector: Selector<IRootState, IUploadFilesState> = (state) =>
  state.mediaManager.uploadFiles

export const uploadFilesModalOpenSelector = createSelector(baseSelector, (state) => state.open)
export const uploadFilesLoadingSelector = createSelector(baseSelector, (state) => state.loading)
export const filesToUploadSelector = createSelector(baseSelector, (state) => state.filesToUpload)
export const mediaManagerOpenSelector = createSelector(
  baseSelector,
  (state) => state.mediaManagerOpen
)
export const onFileSelectSelector = createSelector(baseSelector, (state) => state.onFileSelect)
