// Core
import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
// Components
import { IconButton } from '@material-ui/core'
import { RemoveRedEye, Edit, Add } from '@material-ui/icons'
// Utils
import { getIdFromIri } from 'core/utils'
import { EavResourceType } from 'modules/new-entity/types'
// Types

type Props = {
  rowData: {
    '@id': string
    id: string
    slug: string
    iri: string
    single: boolean
    singleEntity: string | null
  }
  resourceType?: EavResourceType
}

const ShowAction: FC<Props> = (props) => {
  const { rowData, resourceType = EavResourceType.ENTITY } = props
  const history = useHistory()

  const clickHandler = useCallback(() => {
    const { id } = rowData
    history.push(`/${resourceType}/${id}`)
  }, [history, resourceType, rowData])

  const clickHandlerSingle = useCallback(() => {
    const { id } = rowData

    if (rowData.singleEntity) {
      const entityId = getIdFromIri(rowData.singleEntity)
      history.push(`/entities/${id}/edit/${entityId}`)
    } else {
      history.push(`/${resourceType}/${id}/create`)
    }
  }, [history, resourceType, rowData])

  return (
    <div>
      {rowData.single ? (
        <IconButton onClick={clickHandlerSingle}>
          {rowData.singleEntity ? <Edit /> : <Add />}
        </IconButton>
      ) : (
        <IconButton onClick={clickHandler}>
          <RemoveRedEye />
        </IconButton>
      )}
    </div>
  )
}

export default ShowAction
