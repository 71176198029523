import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const selectedFileViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedTitle: {
      padding: theme.spacing(3, 2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    fileDetails: {
      padding: theme.spacing(2),
    },
  })
)

export default selectedFileViewStyles
