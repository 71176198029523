// Core
import { combineReducers } from 'redux'
// Redux
import uploadFilesReducer from './upload-files'
import listReducer from './list'
import folderReducer from './folder'
// Types
import { IMediaManagerState } from './types'

const reducer = combineReducers<IMediaManagerState>({
  uploadFiles: uploadFilesReducer,
  list: listReducer,
  folders: folderReducer,
})

export default reducer
