// Core
import React, { FC } from 'react'
import { FastField, FieldProps } from 'formik'
import { TextFieldProps, TextField as TextFieldMaterial, Box } from '@material-ui/core'
// Hooks
import { useInputValuePath } from 'core/hooks'
// Utils
import { getErrorsState } from 'core/form/hooks/use-field-controller'
// Styles
import useStyles from './text-field.styles'

type TProps = {
  name: string
  localized?: boolean
} & TextFieldProps

const defaultProps = (props: any): TProps => ({
  fullWidth: true,
  variant: 'outlined',
  ...props,
})

const TextField: FC<TProps> = (props) => {
  const classes = useStyles()
  const { name, localized = false, ...rest } = defaultProps(props)
  const valuePath = useInputValuePath(localized, name)

  if (!valuePath) return null

  return (
    <Box className={classes.root}>
      <FastField name={valuePath}>
        {({ field, meta, form }: FieldProps) => {
          const computedOnBlur = (e: any) => {
            field.onBlur(e)
            rest.onBlur?.(e)
          }
          const { hasError, errorMsg } = getErrorsState(meta, form.validateOnMount)

          return (
            <TextFieldMaterial
              {...field}
              {...rest}
              onBlur={computedOnBlur}
              error={hasError}
              helperText={errorMsg}
            />
          )
        }}
      </FastField>
    </Box>
  )
}

export default TextField
