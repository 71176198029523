// Core
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
// Types, reducers
import { IRootState } from './types'
import mediaManagerReducer from 'modules/media/store'
import entityLayoutsReducer from 'modules/entity-layouts/store'
import modalReducer from 'core/store/modal'
import themeReducer from 'core/store/theme'
import attributesModalReducer from 'common/store'

export const createRootReducer = (history: History) => {
  return combineReducers<IRootState>({
    router: connectRouter(history),
    mediaManager: mediaManagerReducer,
    entityLayout: entityLayoutsReducer,
    modal: modalReducer,
    theme: themeReducer,
    attributesModal: attributesModalReducer,
  })
}
