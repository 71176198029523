// Core
import { takeEvery, select } from 'redux-saga/effects'
// Redux
import { ActionTypes, ISelectFolder } from '../types'
import { expandedSelector } from '../selectors'

function* selectFolderWorker(action: ISelectFolder) {
  try {
    const expandedFolders = yield select(expandedSelector)

    const isOpen = expandedFolders.indexOf(action.payload.id)

    if (isOpen < 0) {
      // yield put(getFolders(action.payload.id))
    } else if (action.payload.id !== 'root') {
      // yield put(toggleFolderExpand(action.payload.id))
    }

    // yield put(fetchFilesList({ folder: action.payload.id, page: 1 }, false))
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export function* selectFolderWatcher() {
  yield takeEvery(ActionTypes.SELECT_FOLDER, selectFolderWorker)
}
