// Core
import React, { FC, useCallback } from 'react'
// Components
import { Edit } from 'core/data'
import CrudForm from '../crud-form'
import Sidebar from '../crud-form/sidebar'
// Hooks
import { useRevalidateAll } from 'core/utils/revalidation-service'
// Types
import { ResourceProps } from 'core/data'

const CreateSegments: FC<ResourceProps> = (props) => {
  const { revalidateAll } = useRevalidateAll()

  const onSuccessHandler = useCallback(() => {
    revalidateAll()
  }, [revalidateAll])

  return <Edit {...props} crudForm={CrudForm} sidebar={Sidebar} onSuccess={onSuccessHandler} />
}

export default CreateSegments
