// Core
import React, { FC, useCallback, useMemo, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
// ui
import { Box, Button, CircularProgress, Divider, IconButton, Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
// Components
import AttributesForm from '../../../../../attributes-form'
import CollectionPreview from '../collection-preivew'
import RelationsSelect from 'ui/relations-select'
// utils
import { getIdFromIri } from 'core/utils'
// Types
import { useWidgetController } from '../../../../../../hooks'
import {
  EavResourceType,
  IEntityWidget,
  MutationType,
  SetGlobalWidgetFunc,
  SetWidgetRefDataFunc,
} from '../../../../../../types'

type Props = {
  data: IEntityWidget
  setWidgetRefData: SetWidgetRefDataFunc
  setGlobalWidget: SetGlobalWidgetFunc
  prevValues?: any
  previewMode?: boolean
  needRenderForm?: boolean
  submittedOnce?: boolean
}

const WidgetContent: FC<Props> = (props) => {
  const {
    data,
    setWidgetRefData,
    prevValues,
    setGlobalWidget,
    previewMode,
    needRenderForm = false,
    submittedOnce,
  } = props

  const {
    isCollection,
    typeId,
    isLoading,
    isFetching,
    entityType,
    validationSchema,
    initialValues,
    attributes,
    global,
  } = useWidgetController(data)

  const globalWidgetChangeHandler = useCallback(
    (e: any) => {
      setGlobalWidget(data.id, e)
    },
    [setGlobalWidget, data]
  )

  const isGlobalWidget = Boolean(data.widget)

  const formValues = useMemo(() => {
    if (isGlobalWidget) {
      return initialValues
    }
    return prevValues || initialValues
  }, [initialValues, isGlobalWidget, prevValues])

  const loading = isGlobalWidget ? isFetching : isLoading

  return (
    <>
      {!isCollection && (
        <>
          {!previewMode && (
            <>
              <RelationsSelect
                value={data.widget}
                source={'widgets'}
                label={'Select from global widgets'}
                reqParams={{ widgetType: `/api/widget_types/${typeId}` }}
                onChange={globalWidgetChangeHandler}
                valueField="@id"
                renderEditAction={(valueData) => {
                  const { id, widgetType } = valueData
                  const widgetTypeId = getIdFromIri(widgetType)

                  return (
                    <IconButton
                      color="primary"
                      size="small"
                      component={Link}
                      target="_blank"
                      to={`/widgets/${widgetTypeId}/edit/${id}`}
                      style={{ marginLeft: '20px' }}
                      onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                        event.stopPropagation()
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )
                }}
              />
              <Box my={3}>
                <Divider />
              </Box>
            </>
          )}
        </>
      )}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <AttributesForm
            resourceType={EavResourceType.WIDGET}
            type={MutationType.EDIT}
            mainAttributes={attributes}
            initialValues={formValues}
            validationSchema={validationSchema}
            typeOptions={entityType?.options}
            // @ts-ignore
            formRef={(formRef) => {
              if (!previewMode) {
                setWidgetRefData(data.id, formRef, attributes)
              }
            }}
            disabled={previewMode}
            widget={data}
            errorsLabelId={String(data.id)}
            needRenderForm={needRenderForm}
            validateOnMount={submittedOnce}
          />
          {!isCollection && (
            <Box mt={2}>
              {isGlobalWidget && (
                <Box mb={1}>
                  <Typography variant="caption">
                    *Any change you do will be updated in every place this global widget is used
                  </Typography>
                </Box>
              )}
              <Button
                onClick={() => global.saveGlobalWidget(isGlobalWidget ? 'edit' : 'create')}
                color="secondary"
                size="large"
                variant="contained"
                disabled={global.isLoading}
              >
                {isGlobalWidget ? 'Save global widget' : 'Save as global'}
              </Button>
            </Box>
          )}
        </>
      )}
      {isCollection && <CollectionPreview widgetData={data} />}
    </>
  )
}

export default WidgetContent
