// Core
import React, { FC } from 'react'
import { Icon, Checkbox } from '@material-ui/core'
// Utils
import { getFormatFromMime, isImageFromMime } from 'modules/media/utils'
// Types
import { IFile } from 'modules/media/types'
// Styles
import useStyles from './grid-item.styles'
// Hooks
import { useMediaModalOpen } from 'modules/media/hooks'
import clsx from 'clsx'

type Props = {
  itemData: IFile
  selected: any
  setSelected: (file: any) => void
  inModal: boolean
}

const GridItem: FC<Props> = (props) => {
  const classes = useStyles()
  const { itemData, selected, setSelected, inModal } = props
  const isImage = isImageFromMime(itemData.mimeType)
  const modalOpen = useMediaModalOpen()

  return (
    <div className={classes.root}>
      <Checkbox
        className={clsx(classes.checkbox, selected ? 'selected-item' : '')}
        checked={itemData.id === selected}
        onChange={() => setSelected(itemData.id)}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      <div
        className={classes.content}
        onClick={() => (!inModal ? modalOpen(itemData) : setSelected(itemData.id))}
      >
        <div className={classes.preview}>
          {isImage ? (
            <img src={itemData.thumbnailUrls.tile} alt="" />
          ) : (
            <div className={classes.fileIcon}>
              <div className={classes.format}>{getFormatFromMime(itemData.mimeType)}</div>
              <Icon className="icon-file" />
            </div>
          )}
        </div>
        <div className={classes.info}>{itemData.name}</div>
      </div>
    </div>
  )
}

export default GridItem
