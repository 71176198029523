// Core
import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { defaultThemeSizes } from 'core/themes/default/default'

export const createArr = (length: number) =>
  Array(length)
    .fill(length)
    .map((_, index) => index)

type Props = {
  hasSidebar?: boolean
}

const FormPageSkeleton: FC<Props> = ({ hasSidebar }) => {
  return (
    <Box bgcolor="common.white">
      <Box
        display="flex"
        px={4}
        py={2}
        height={defaultThemeSizes.pageHeaderHeight + 1}
        justifyContent="space-between"
        style={{ borderColor: 'rgb(224, 224, 224)' }}
        borderBottom={1}
      >
        <Skeleton variant="rect" height={33} width={300} />

        <Box display="flex" alignItems="center" style={{ columnGap: 16 }}>
          <Skeleton variant="rect" height={37} width={146} />
          <Skeleton variant="rect" height={37} width={146} />
          <Skeleton variant="rect" height={37} width={146} />
        </Box>
      </Box>
      <Box display="flex" justifyContent="stretch" alignItems="flex-start">
        <Box flex={1} display="flex" flexWrap="wrap" style={{ columnGap: '20px' }} px={4}>
          {createArr(30).map((index) => (
            <Skeleton component="div" key={index} width="calc(50% - 10px)" height={60} />
          ))}
        </Box>
        {hasSidebar && (
          <Box width={360} px={2} borderLeft={1} style={{ borderColor: 'rgb(224, 224, 224)' }}>
            {createArr(15).map((index) => (
              <Skeleton key={index} width="100%" height={60} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default FormPageSkeleton
