import {
  ActionTypes,
  IGetFolders,
  IGetFoldersSuccess,
  IRemoveFolder,
  IRemoveFolderSuccess,
  ISaveFolder,
  ISaveFolderSuccess,
  ISelectFolder,
  IToggleFolderExpand,
  IToggleFormModal,
  IUpdateFolderData,
} from './types'

export const getFolders = (folder: string = 'root', needExpand = true): IGetFolders => ({
  type: ActionTypes.GET_FOLDERS,
  payload: { folder, needExpand },
})

export const getFoldersSuccess = (data: any): IGetFoldersSuccess => ({
  type: ActionTypes.GET_FOLDERS_SUCCESS,
  payload: { data },
})

export const saveFolder = (data: any, type: 'create' | 'edit' = 'create'): ISaveFolder => ({
  type: ActionTypes.SAVE_FOLDER,
  payload: { data, type },
})

export const saveFolderSuccess = (
  data: any,
  type: 'create' | 'edit' = 'create'
): ISaveFolderSuccess => ({
  type: ActionTypes.SAVE_FOLDER_SUCCESS,
  payload: { data, type },
})

export const selectFolder = (id: string): ISelectFolder => ({
  type: ActionTypes.SELECT_FOLDER,
  payload: { id },
})

export const toggleFolderExpand = (id: string): IToggleFolderExpand => ({
  type: ActionTypes.TOGGLE_FOLDER_EXPAND,
  payload: { id },
})

export const toggleModalForm = (
  open: boolean,
  type?: 'edit' | 'create',
  data?: any
): IToggleFormModal => ({
  type: ActionTypes.TOGGLE_FORM_MODAL,
  payload: { open, type, data },
})

export const removeFolder = (id: string, option: string, onDelete?: () => void): IRemoveFolder => ({
  type: ActionTypes.REMOVE_FOLDER,
  payload: { id, option, onDelete },
})

export const removeFolderSuccess = (id: string): IRemoveFolderSuccess => ({
  type: ActionTypes.REMOVE_FOLDER_SUCCESS,
  payload: { id },
})

export const updateFolderData = (id: string, data: any): IUpdateFolderData => ({
  type: ActionTypes.UPDATE_FOLDER_DATA,
  payload: { id, data },
})

export type MediaFolderActionTypes =
  | IGetFolders
  | IGetFoldersSuccess
  | ISelectFolder
  | IToggleFolderExpand
  | IToggleFormModal
  | IRemoveFolder
  | IRemoveFolderSuccess
  | IUpdateFolderData
  | ISaveFolderSuccess
