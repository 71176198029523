import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
// Hooks
import { useTranslationContext } from 'modules/translations/hooks'
// Components
import { TableItem } from '../table-item'

export const TranslationTable = () => {
  const {
    query: { data, isLoading },
  } = useTranslationContext()

  const list = !isLoading ? data?.['hydra:member'] : []

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={300}>Original text</TableCell>
            <TableCell>Translation</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        {!isLoading && (
          <TableBody>
            {list?.map((item: any) => (
              <TableItem key={item.id} data={item} />
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}
