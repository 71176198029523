// Core
import { takeEvery, put, call } from 'redux-saga/effects'
// Redux
import { ActionTypes, IUpdateFile } from '../types'
import { updateFileSuccess } from '../actions'
// Services
import { httpService } from 'core/data'
// Types

function* updateFileWorker(action: IUpdateFile) {
  try {
    const { file } = action.payload

    yield httpService.put(`media/${file.id}`, file)
    const newData = yield call(httpService.get, `media/${file.id}`)

    yield put(updateFileSuccess(newData.data))
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export function* updateFileWatcher() {
  yield takeEvery(ActionTypes.UPDATE_FILE, updateFileWorker)
}
