import { createTheme } from '@material-ui/core'

export const defaultThemeSizes = {
  appBarHeight: 64,
  pageHeaderHeight: 60,
  drawerWidthClose: 64,
  drawerWidth: 212,
  rightSidebarWidth: 360,
  modalHeaderHeight: 56,
  modalStepperHeight: 60,
}

export const defaultThemeColors = {
  contentBg: '#f4f6f8',
  purpleLight: '#e4e2ff',
  purpleDark: '#bcb8ff',
  disableBg: '#eceff1',
  hiddenBg: '#0112fd0f',
}

const defaultTheme = createTheme({
  typography: {
    fontFamily: 'Roboto',
  },
  palette: {
    common: {
      black: 'rgba(26, 29, 41, 1)',
      white: 'rgba(255, 255, 255, 1)',
    },
    background: {
      paper: 'rgba(255, 255, 255, 1)',
      default: 'rgba(244, 246, 248, 1)',
    },
    primary: {
      light: '#7986cb',
      main: '#3f51b5',
      dark: '#303f9f',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(255, 92, 141, 1)',
      main: 'rgba(216, 27, 96, 1)',
      dark: 'rgba(160, 0, 55, 1)',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },

  overrides: {
    MuiAppBar: {
      root: {
        height: defaultThemeSizes.appBarHeight,
        boxShadow: 'none',
      },
    },
    MuiDrawer: {
      paper: {
        top: defaultThemeSizes.appBarHeight,
        height: `calc(100% - ${defaultThemeSizes.appBarHeight}px)`,
      },
    },
    MuiDialogActions: {
      spacing: {
        padding: '8px 24px',
      },
    },
    MuiList: {
      root: {
        padding: 0,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 'auto',
        marginRight: 12,
      },
    },
    MuiGrid: {
      item: {
        outline: 'none',
      },
    },
    MuiInputBase: {
      root: {
        '&.Mui-error': {
          background: '#fff1f1',
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&.Mui-error': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
  },
})

export default defaultTheme
