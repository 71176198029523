import React, { FC } from 'react'
import { DataView, ResourceProps } from 'core/data'
import { getTableColumn } from 'core/data/components/data-view'
import { useTranslation } from 'react-i18next'
import { ColumnType } from 'core/data/components/data-view/types'
import { NavigationActionsColumn } from './components'

const NavigationList: FC<ResourceProps> = (props) => {
  const { t } = useTranslation()

  const getActionsColumns = (resourceName: string, t: any): ColumnType => {
    return {
      sorting: false,
      filtering: false,
      width: 130,
      title: t('table.actions'),
      render: (rowData: any) => (
        <NavigationActionsColumn resource={resourceName} rowData={rowData} />
      ),
    }
  }

  return (
    <DataView
      resourceProps={props}
      columns={[
        getActionsColumns(props.name, t),
        getTableColumn({ title: t('table.name'), field: 'title' }),
      ]}
    />
  )
}

export default NavigationList
