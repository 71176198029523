// Core
import { all, fork } from 'redux-saga/effects'
// Sagas
import { getFoldersWatcher } from './get-folders'
import { saveFolderWatcher } from './save-folders'
import { selectFolderWatcher } from './select-folder'
import { removeFolderWatcher } from './remove-folder'

// eslint-disable-next-line func-names
export default function* () {
  yield all([
    fork(getFoldersWatcher),
    fork(saveFolderWatcher),
    fork(selectFolderWatcher),
    fork(removeFolderWatcher),
  ])
}
