import * as yup from 'yup'

export const getFormatFromMime = (mimeType: string) => {
  const split = mimeType.split('/')
  return `${split[split.length - 1]}`
}

export const isImageFromMime = (mimeType: string): boolean => {
  return mimeType.indexOf('image/') >= 0
}

export const transformFileName = (file: File) => {
  const [name, mimeType] = file.name.split('.')
  const slicedName = name.slice(0, 235)
  const transformedName = `${slicedName}.${mimeType}`

  return new File([file], transformedName, { type: file.type })
}

export const mediaFormValidationSchema = yup.object({
  name: yup.string().required(),
})
