import React, { useCallback, useRef, useState } from 'react'
import { Grid, LinearProgress } from '@material-ui/core'
import { TextField } from 'core/form'
import { useQuery } from 'react-query'
import { httpService } from 'core/data'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import RelationsSelect from 'ui/relations-select'
import RelationsField from 'core/form/components/relations-field'

const InternalFields = () => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField('entity')
  const [selectedEntityType, setSelectedEntityType] = useState<string>('')
  const enabled = useRef(Boolean(field.value))

  const { isLoading } = useQuery(
    ['internal-link-entity', field.value],
    async () => {
      const entityRes = await httpService.get<any>(`entities/${field.value}`)
      return entityRes.data.entityType
    },
    {
      enabled: enabled.current,
      cacheTime: 0,
      onSuccess: (res) => {
        setSelectedEntityType(res)
        enabled.current = false
      },
    }
  )

  const changeHandler = useCallback(
    (value) => {
      setSelectedEntityType(value)
      helpers.setValue(null)
    },
    [helpers]
  )

  return (
    <>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      {!isLoading && (
        <Grid item xs={4}>
          <RelationsSelect
            valueField={'@id'}
            label={t('links.internal_link_entity-type')}
            onChange={changeHandler}
            source={'entity_types'}
            value={selectedEntityType}
            reqParams={{
              urlable: true,
            }}
            aqaDataAttr="internal-link-type"
          />
        </Grid>
      )}

      {(Boolean(selectedEntityType?.length) || (Boolean(meta.error) && meta.touched)) && (
        <>
          <Grid item xs={4}>
            <RelationsField
              valueField={'id'}
              source={'entities'}
              name={'entity'}
              label={t('select.entity')}
              reqParams={{ entityType: selectedEntityType }}
              aqaDataAttr="internal-link-entity"
              entityTypeIri={selectedEntityType}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField name="anchor" label={`${t('links.link-target')}`} />
          </Grid>
        </>
      )}
    </>
  )
}

export default InternalFields
