// Core
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { CreateNewFolder, NoteAdd } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
// Redux
import { toggleModalForm, toggleUploadModal } from 'modules/media/store'
// Hooks
import { useAppContext } from 'core/app'
// Types
import { Permissions } from 'core/app'
// Styles
import useStyles from './create-button.styles'

const CreateButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    actions: { userCan },
  } = useAppContext()

  const uploadFileHandler = useCallback(() => {
    dispatch(toggleUploadModal(true))
  }, [dispatch])

  const createFolderHandler = useCallback(() => {
    dispatch(toggleModalForm(true, 'create'))
  }, [dispatch])

  return (
    <div className={classes.root}>
      {userCan('media', Permissions.CREATE, 'system') && (
        <Button
          color="primary"
          variant="contained"
          startIcon={<NoteAdd />}
          onClick={uploadFileHandler}
        >
          {t('media.add-file')}
        </Button>
      )}
      {userCan('media_folders', Permissions.CREATE, 'system') && (
        <Button
          color="primary"
          variant="outlined"
          startIcon={<CreateNewFolder />}
          onClick={createFolderHandler}
        >
          {t('media.add-folder')}
        </Button>
      )}
    </div>
  )
}

export default CreateButton
