// Core
import React, { FC, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, IconButton } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
// Hooks
import { useDelete } from 'core/data'
import { useNotify } from 'core/hooks'
import { Permissions, useAppContext } from 'core/app'
// Styles
import useStyles from './actions-column.styles'
import { useConfirmation } from 'core/confirmation'
import { useTranslation } from 'react-i18next'

type TProps = {
  resource: string
  rowData: any
  onDelete?: () => void
  forceDeleteMsg?: string
}

const ActionsColumn: FC<TProps> = (props) => {
  const classes = useStyles()
  const notify = useNotify()

  const {
    resource,
    rowData: { id },
    onDelete,
    forceDeleteMsg,
  } = props

  const {
    actions: { userCan },
  } = useAppContext()

  const history = useHistory()
  const { mutate: remove } = useDelete(resource, { forceMsg: forceDeleteMsg })

  const { t } = useTranslation()
  const { setConfirmation } = useConfirmation()

  const deleteSuccessHandler = useCallback(() => {
    notify(`${resource} ${t('actions.deleted')}`, {
      type: 'success',
    })
    if (onDelete) onDelete()
  }, [notify, resource, t, onDelete])

  const deleteClickHandler = useCallback(() => {
    remove(id, {
      onSuccess: deleteSuccessHandler,
    })
  }, [deleteSuccessHandler, id, remove])

  const removeWithConfirmation = useCallback(
    (onSuccess: () => void) => {
      setConfirmation({
        open: true,
        title: t('global.delete'),
        content: t('notify.delete'),
        onSuccess,
      })
    },
    [setConfirmation, t]
  )

  const editClickHandler = useCallback(() => {
    history.push(`${resource}/${id}`)
  }, [history, id, resource])

  return (
    <Box className={classes.root}>
      {(userCan(resource, Permissions.DELETE) ||
        userCan(resource, Permissions.DELETE, 'system') ||
        userCan(resource, Permissions.DELETE, 'widgetType')) && (
        <IconButton
          aria-label="delete"
          onClick={() => removeWithConfirmation(() => deleteClickHandler())}
        >
          <Delete />
        </IconButton>
      )}

      {(userCan(resource, Permissions.EDIT) ||
        userCan(resource, Permissions.EDIT, 'system') ||
        userCan(resource, Permissions.EDIT, 'widgetType')) && (
        <IconButton aria-label="edit" onClick={editClickHandler}>
          <Edit />
        </IconButton>
      )}
    </Box>
  )
}

export default ActionsColumn
