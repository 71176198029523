// Core
import React, { FC, memo, useCallback } from 'react'
import { IconButton } from '@material-ui/core'
// Types
import { ActionBaseProps } from '../../types'

const ActionBase: FC<ActionBaseProps> = (props) => {
  const { icon, onClick, rowData } = props

  const clickHandler = useCallback(() => {
    onClick(rowData)
  }, [onClick, rowData])

  return <IconButton onClick={clickHandler}>{icon}</IconButton>
}

export default memo(ActionBase)
