// Core
import React, { FC, useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Collapse, List, ListItem } from '@material-ui/core'
// Types
import { FoldersMap, FolderTreeItem } from 'modules/media/types'
// Styles
import useStyles from './folder-item.styles'
import { Label } from './components'
// Actions
import { toggleFolderExpand } from '../../../../store'

type TProps = {
  data: FolderTreeItem
  folderData: FoldersMap | any
  onClick?: () => void
  selectFolder: (value: string) => void
  selectedFolder: any
  expandedFolders: string[]
}

const FolderItem: FC<TProps> = (props) => {
  const dispatch = useDispatch()
  const { data, children, folderData, selectFolder, selectedFolder, expandedFolders } = props
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    const folderId = String(folderData[data.id].id)
    if (expandedFolders.includes(folderId)) {
      setOpen(true)
    } else {
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedFolders])

  const handleClick = useCallback(
    (id) => {
      dispatch(toggleFolderExpand(String(id)))
    },
    [dispatch]
  )

  return (
    <ListItem className={classes.root} selected={selectedFolder === data.id}>
      <Label
        open={open}
        onClick={handleClick}
        treeData={data}
        data={folderData[data.id]}
        selectFolder={selectFolder}
      />
      {children ||
        (data.children && (
          <Collapse in={open}>
            <List className={classes.listRoot}>
              {data.children.map((item) => (
                <FolderItem
                  key={item.id}
                  folderData={folderData}
                  data={item}
                  selectFolder={selectFolder}
                  selectedFolder={selectedFolder}
                  expandedFolders={expandedFolders}
                />
              ))}
            </List>
          </Collapse>
        ))}
    </ListItem>
  )
}

export default FolderItem
