// Core
import React, { FC, ReactElement, RefObject, useImperativeHandle } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { Grid } from '@material-ui/core'
import { Portal } from '@material-ui/core'
// Components
import { FormLang, LanguageProvider } from 'core/data'
import FormControl from './components/form-control'
import FormErrorIndicator from './components/form-error-indicator'
// Types
import { EavResourceType } from 'modules/new-entity/types'
import { FormRef, IEntity, IEntityWidget, MutationType, SortedAttribute } from '../../types'

type TProps = {
  type: MutationType
  asideRef?: RefObject<HTMLElement>
  mainAttributes: SortedAttribute[]
  asideAttributes?: SortedAttribute[]
  initialValues: any
  validationSchema: any
  resourceType: any
  typeOptions: any
  formRef?: FormRef
  asideHolder?: ReactElement
  entity?: IEntity
  disabled?: boolean
  widget?: IEntityWidget
  unsavedChanges?: RefObject<HTMLElement>
  errorsLabelId?: string
  needRenderForm?: boolean
  validateOnMount?: boolean
}

const AttributesForm: FC<TProps> = (props) => {
  const {
    type,
    asideRef,
    mainAttributes,
    asideAttributes,
    validationSchema,
    initialValues,
    resourceType,
    typeOptions,
    formRef,
    asideHolder,
    entity,
    disabled,
    widget,
    errorsLabelId,
    needRenderForm = true,
    validateOnMount = false,
  } = props

  const formikBag = useFormik({
    onSubmit: () => {},
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount,
  })

  useImperativeHandle(formRef, () => formikBag)

  if (!needRenderForm) return <FormErrorIndicator id={errorsLabelId} errors={formikBag.errors} />

  const selfType = resourceType === EavResourceType.WIDGET ? 'widget' : 'entity'

  const systemSlug = mainAttributes?.find((item: any) => item.attribute.slug === 'slug')
  const cloakedUrl = mainAttributes?.find((item: any) => item.attribute.slug === 'cloaked_url')

  return (
    <FormikProvider value={formikBag}>
      <Form>
        <LanguageProvider notSystem>
          <FormLang />
          <FormErrorIndicator id={errorsLabelId} errors={formikBag.errors} />
          <Grid container spacing={3}>
            {mainAttributes.map((item: any) => (
              <FormControl
                resourceType={resourceType}
                key={item.id}
                name={item.attribute['@id']}
                data={item}
                options={typeOptions}
                allData={mainAttributes}
                pathToHierarchicalOptions={item.attribute['@id']}
                selfType={selfType}
                type={type}
                entity={entity}
                disabled={disabled}
                widget={widget}
                slugs={{
                  system: systemSlug?.attribute['@id'],
                  cloaked: cloakedUrl?.attribute['@id'],
                }}
              />
            ))}
          </Grid>
          {asideAttributes && asideRef?.current && (
            <Portal container={asideRef?.current}>
              <Grid container spacing={2}>
                {asideAttributes.map((item: any) => (
                  <FormControl
                    resourceType={resourceType}
                    key={item.id}
                    name={item.attribute['@id']}
                    data={item}
                    options={typeOptions}
                    allData={asideAttributes}
                    pathToHierarchicalOptions={item.attribute['@id']}
                    selfType={selfType}
                    type={type}
                    entity={entity}
                    disabled={disabled}
                  />
                ))}
              </Grid>
              {asideHolder}
            </Portal>
          )}
        </LanguageProvider>
      </Form>
    </FormikProvider>
  )
}

export default AttributesForm
