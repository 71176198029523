// Core
import { takeEvery, put, select } from 'redux-saga/effects'
// Redux
import { mediaManagerFileUploadTypes } from '../../upload-files'
import { fetchFilesList } from '../actions'
import { selectedFolderSelector } from '../../folder'

function* updateOnUploadWorker() {
  try {
    const activeFolder = yield select(selectedFolderSelector)
    yield put(fetchFilesList({ page: 1, folder: activeFolder }))
  } catch (e) {
    console.error(e) // eslint-disable-line
  }
}

export function* updateOnUploadWatcher() {
  yield takeEvery(
    mediaManagerFileUploadTypes.ActionTypes.UPLOAD_FILES_SUCCESS,
    updateOnUploadWorker
  )
}
