/**
 * check if property exist in object
 * @param prop
 * @param o
 */
export const hasProp = <K extends string>(prop: K, o: object): o is { [prop in K]: unknown } =>
  prop in o

/**
 * Check if value is array
 * @param arr
 */
export const isArray = (arr: unknown): arr is unknown[] => Array.isArray(arr)
