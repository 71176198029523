// Core
import { all, fork } from 'redux-saga/effects'
// Sagas
import { fetchListWatcher } from './fetch-list'
import { updateOnUploadWatcher } from './update-on-upload'
import { deleteFileWatcher } from './delete-file'
import { updateFileWatcher } from './update-file'

// eslint-disable-next-line func-names
export default function* () {
  yield all([
    fork(fetchListWatcher),
    fork(updateOnUploadWatcher),
    fork(deleteFileWatcher),
    fork(updateFileWatcher),
  ])
}
