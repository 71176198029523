import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      paddingLeft: '30px',
      alignItems: 'center',
      color: '#546E7A',
    },
    arrowIcon: {
      position: 'absolute',
      left: 0,
      transition: 'all .3s ease',
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      width: '100%',
      padding: '5px 0 3px 36px',
      margin: '0 0 0 -36px',
    },
    actionsButton: {
      marginLeft: 'auto',
    },
    folderIcon: {
      fontSize: 20,
      marginRight: 10,
    },
  })
)

export default styles
