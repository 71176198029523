import React, { FC } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

type Props = {
  show: boolean
  columnsCount: number
}

const RowsSkeleton: FC<Props> = ({ show, columnsCount }) => {
  const createArr = (length: number) => Array.from(Array(length).keys())

  return (
    <>
      {createArr(20).map((rowIndex) => (
        <TableRow key={rowIndex}>
          {createArr(columnsCount).map((columnIndex) => (
            <TableCell key={columnIndex}>
              <Skeleton variant="rect" height={31} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}

export default RowsSkeleton
