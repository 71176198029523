import { useDispatch, useSelector } from 'react-redux'
import { DropzoneState, useDropzone } from 'react-dropzone'
import { useCallback, useState } from 'react'
import {
  setFilesToUpload,
  uploadFiles as uploadFilesAction,
  deleteFile as deleteFileAction,
  uploadFilesLoadingSelector,
  filesToUploadSelector,
  uploadFilesModalOpenSelector,
  toggleUploadModal,
} from '../store'
import { IFileToUpload } from '../types'
import { FileToUpload } from '../classes'
import { transformFileName } from '../utils'
import { useQueryClient } from 'react-query'
import { useGetDefaultFolder } from './use-get-default-folder'

type UseUploadFiles = () => {
  open: boolean
  dropzoneProps: DropzoneState
  uploadFiles: () => void
  loading: boolean
  filesList: IFileToUpload[]
  deleteFile: (file: IFileToUpload) => void
  closeModal: () => void
  folder: any
  changeFolder: (folderId: string | undefined) => void
}

export const useUploadFiles: UseUploadFiles = () => {
  const open = useSelector(uploadFilesModalOpenSelector)
  const filesToUpload = useSelector(filesToUploadSelector)
  const loading = useSelector(uploadFilesLoadingSelector)
  const dispatch = useDispatch()
  const [folder, setFolder] = useState()
  const queryClient = useQueryClient()

  useGetDefaultFolder({
    onSuccess: (data) => {
      if (data) {
        setFolder(data.id)
      }
    },
  })

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const transformFiles = acceptedFiles.map((file) => {
        const transformed = transformFileName(file)

        return new FileToUpload(transformed)
      })

      dispatch(setFilesToUpload(transformFiles, folder))
    },
    [dispatch, folder]
  )

  const uploadFiles = useCallback(() => {
    dispatch(uploadFilesAction())
  }, [dispatch])

  const deleteFile = useCallback(
    (file: FileToUpload) => {
      dispatch(deleteFileAction(file))
    },
    [dispatch]
  )

  const closeModal = useCallback(() => {
    setFolder(undefined)
    queryClient.invalidateQueries('media')
    dispatch(toggleUploadModal(false))
  }, [dispatch, queryClient])

  const dropzoneProps = useDropzone({ onDrop })

  const changeFolder = useCallback((folderId: string | undefined) => {
    setFolder(folderId)
  }, [])

  return {
    open,
    dropzoneProps,
    uploadFiles,
    loading,
    filesList: filesToUpload,
    deleteFile,
    closeModal,
    folder,
    changeFolder,
  }
}
