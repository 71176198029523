import { Action } from 'redux'
import { IFile, ListParams } from 'modules/media/types'

export interface IFileManagerListState {
  loading: boolean
  list: IFile[]
  params: ListParams
  total: number
  formModal: {
    open: boolean
    data: IFile | undefined
    type: 'create' | 'edit'
    onDelete?: () => void
  }
}

export enum ActionTypes {
  FETCH_LIST = '@@media-manager/list/FETCH_LIST',
  FETCH_LIST_SUCCESS = '@@media-manager/list/FETCH_LIST_SUCCESS',
  FETCH_LIST_ERROR = '@@media-manager/list/FETCH_LIST_ERROR',

  DELETE_FILE = '@@media-manager/list/DELETE_FILE',
  DELETE_FILE_SUCCESS = '@@media-manager/list/DELETE_FILE_SUCCESS',
  DELETE_FILE_ERROR = '@@media-manager/list/DELETE_FILE_ERROR',

  UPDATE_FILE = '@@media-manager/list/UPDATE_FILE',
  UPDATE_FILE_SUCCESS = '@@media-manager/list/UPDATE_FILE_SUCCESS',

  TOGGLE_FORM_MODAL = '@@media-manager/list/TOGGLE_FORM_MODAL',
}

export interface IFetchListAction extends Action<ActionTypes.FETCH_LIST> {
  readonly payload: {
    params?: ListParams
    withLoading: boolean
  }
}

export interface IFetchListSuccess extends Action<ActionTypes.FETCH_LIST_SUCCESS> {
  readonly payload: {
    data: any
    total: number
  }
}

export interface IDeleteFileAction extends Action<ActionTypes.DELETE_FILE> {
  readonly payload: {
    file: IFile
  }
}

export interface IDeleteFileSuccessAction extends Action<ActionTypes.DELETE_FILE_SUCCESS> {
  readonly payload: {
    file: IFile
  }
}

export interface IToggleFormModal extends Action<ActionTypes.TOGGLE_FORM_MODAL> {
  readonly payload: {
    open: boolean
    type: 'create' | 'edit'
    data?: any
    onDelete?: () => void
  }
}

export interface IUpdateFile extends Action<ActionTypes.UPDATE_FILE> {
  readonly payload: {
    file: IFile
  }
}

export interface IUpdateFileSuccess extends Action<ActionTypes.UPDATE_FILE_SUCCESS> {
  readonly payload: {
    file: IFile
  }
}
