// Core
import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Components
import { DataView } from 'core/data'
import { FolderColumn, GridItem, NameColumn, Sidebar, SizeColumn } from '../../../list/components'
import { DefaultActionColumns, getActions, getTableColumn } from 'core/data/components/data-view'
import { SelectedFileView } from './components'
// Hooks
import { useGetDefaultFolder } from 'modules/media/hooks/use-get-default-folder'
// Store
import { selectFolder } from '../../../../store'

export const MediaLibrary = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const defaultFolder = useGetDefaultFolder({
    onSuccess: (data) => {
      if (data) {
        // @ts-ignore
        dispatch(selectFolder(data.id))
      }
    },
  })

  if (defaultFolder.isLoading || defaultFolder.isFetching) return null

  return (
    <DataView
      showCheckBox
      resourceProps={{ name: 'media' }}
      controllerOptions={{
        filterChangeUrl: false,
        defaultFilters: {
          folder: defaultFolder.data?.id,
        },
      }}
      sidebar={<Sidebar showCreateButton={false} />}
      columns={[
        getActions([DefaultActionColumns.DELETE], t),
        getTableColumn({ title: 'Name', width: 'auto' }, 'custom', NameColumn),
        getTableColumn({ title: 'Size', width: '100' }, 'custom', SizeColumn),
        getTableColumn({ title: 'Folder', field: 'folder' }, 'custom', FolderColumn),
        getTableColumn({ title: 'Author', field: 'createdBy' }, 'reference', null, {
          field: 'email',
          source: 'users',
        }),
        getTableColumn({ title: 'Created at', field: 'createdAt', width: 124 }, 'date'),
      ]}
      hasGrid={true}
      gridItemComponent={GridItem}
      inModal={true}
    >
      <SelectedFileView />
    </DataView>
  )
}
