import { Action } from 'redux'
import { IFile, IFileToUpload } from 'modules/media/types'
import { FileToUpload } from 'modules/media/classes'

export interface IUploadFilesState {
  open: boolean
  filesToUpload: IFileToUpload[]
  loading: boolean
  mediaManagerOpen: boolean
  onFileSelect?: (file: any) => void
}

export enum ActionTypes {
  TOGGLE_UPLOAD_MODAL = '@@media-manager/TOGGLE_UPLOAD_MODAL',

  SET_FILES_TO_UPLOAD = '@@media-manager/SET_FILES_TO_UPLOAD',

  UPLOAD_FILES = '@@media-manager/UPLOAD_FILES',
  UPLOAD_FILES_SUCCESS = '@@media-manager/UPLOAD_FILES_SUCCESS',

  UPLOAD_FILE = '@@media-manager/UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS = '@@media-manager/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR = '@@media-manager/UPLOAD_FILE_ERROR',

  DELETE_FILE = '@@media-manager/DELETE_FILE',
  RENAME_FILE = '@@media-manager/RENAME_FILE',

  UPDATE_UPLOAD_PROGRESS = '@@media-manager/UPDATE_UPLOAD_PROGRESS',
  SET_UPLOAD_FILE_ERROR = '@@media-manager/SET_UPLOAD_FILE_ERROR',
  CANCEL_FILE_UPLOAD = '@@media-manager/CANCEL_FILE_UPLOAD',

  TOGGLE_MANAGER_MODAL = '@@media/manager/TOGGLE_MANAGER_MODAL',
}

export interface IToggleUploadModalAction extends Action<ActionTypes.TOGGLE_UPLOAD_MODAL> {
  readonly payload: {
    value: boolean
  }
}

export interface ISetFilesToUploadAction extends Action<ActionTypes.SET_FILES_TO_UPLOAD> {
  readonly payload: {
    files: IFileToUpload[]
    folder?: string
  }
}

export interface IUploadFilesAction extends Action<ActionTypes.UPLOAD_FILES> {}

export interface IUploadFilesSuccessAction extends Action<ActionTypes.UPLOAD_FILES_SUCCESS> {}

export interface IUploadFileAction extends Action<ActionTypes.UPLOAD_FILE> {
  readonly payload: {
    file: IFileToUpload
  }
}

export interface IUploadFileSuccessAction extends Action<ActionTypes.UPLOAD_FILE_SUCCESS> {
  readonly payload: {
    file: IFileToUpload
    mediaData: IFile
  }
}

export interface IDeleteFileAction extends Action<ActionTypes.DELETE_FILE> {
  readonly payload: {
    file: FileToUpload
  }
}

export interface IRenameFileAction extends Action<ActionTypes.RENAME_FILE> {
  readonly payload: {
    file: FileToUpload
    name: string
  }
}

export interface IUpdateUploadProgress extends Action<ActionTypes.UPDATE_UPLOAD_PROGRESS> {
  readonly payload: {
    file: FileToUpload
    value: number
  }
}

export interface ISetUploadFileError extends Action<ActionTypes.SET_UPLOAD_FILE_ERROR> {
  readonly payload: {
    file: FileToUpload
    error: any
  }
}

export interface ICancelFileUpload extends Action<ActionTypes.CANCEL_FILE_UPLOAD> {
  readonly payload: {
    file: IFileToUpload
  }
}

export interface IToggleManagerModal extends Action<ActionTypes.TOGGLE_MANAGER_MODAL> {
  readonly payload: {
    value: boolean
    onFileSelect?: (file: IFile) => void
  }
}
