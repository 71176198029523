// Core
import React, { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  MenuList,
  Popover,
  TextField,
  TextFieldProps,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Pagination, Skeleton } from '@material-ui/lab'
import { Add, ArrowDropDown, ArrowDropUp, Close, Edit } from '@material-ui/icons'
import setWith from 'lodash.setwith'
// Components
import SearchField from 'ui/search-field'
import Actions from './actions'
// Hooks
import { useActions } from './use-actions'
// Services/Utils
import { httpService } from 'core/data'
import { getIdFromIri } from 'core/utils'
// Types
import { HydraResponse } from 'core/types'

type Value = string | number | null
type ValueProp = Value | Value[]

type OptionLabelFunc = (obj: unknown) => string

export type RelationsSelectProps = {
  source: string
  reqParams?: Record<string, unknown>
  optionLabelField?: string | OptionLabelFunc
  valueField?: string
  multiple?: boolean
  value?: ValueProp
  onChange?: (value: unknown) => void
  disabledOptions?: string[]
  aqaDataAttr?: string
  entityTypeIri?: string
  renderEditAction?: (valueData: any) => ReactNode
} & Pick<
  TextFieldProps,
  | 'error'
  | 'helperText'
  | 'size'
  | 'onBlur'
  | 'onFocus'
  | 'className'
  | 'variant'
  | 'label'
  | 'disabled'
  | 'required'
>

type Option = Record<any, any>

type ListResponse = Pick<HydraResponse<Option>, 'hydra:member' | 'hydra:totalItems'>

const QUERY_KEY = 'relations-list'

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 0),
    background: theme.palette.grey[200],
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  arrow: {
    color: theme.palette.action.active,
  },
  option: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover .crud-actions': {
      opacity: 1,
    },
    '& .crud-actions': {
      opacity: 0,
    },
  },
}))

const RelationsSelect: FC<RelationsSelectProps> = (props) => {
  const {
    source,
    reqParams,
    label,
    multiple,
    value: valueProp,
    onChange,
    valueField = 'id',
    optionLabelField = 'name',
    error,
    helperText,
    size,
    disabled,
    variant = 'outlined',
    disabledOptions = [],
    className,
    aqaDataAttr,
    entityTypeIri,
    required,
    renderEditAction,
  } = props

  const classes = useStyles()

  const isControlled = Boolean(onChange)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>()
  const isOpen = Boolean(anchorEl)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const pagesCount = Math.ceil(totalItems / 30)

  const getOptionLabel = useCallback(
    (option: Option) => {
      if (optionLabelField) {
        const isFunc = typeof optionLabelField === 'function'
        if (isFunc) return optionLabelField(option)
        return option[optionLabelField]
      }
      return option.name
    },
    [optionLabelField]
  )

  const [internalValue, setInternalValue] = useState<ValueProp>(() => {
    if (valueProp) return valueProp
    return multiple ? [] : null
  })

  const value = isControlled ? valueProp : internalValue

  const dataQuery = useQuery(
    [QUERY_KEY, source, search, reqParams, page],
    () => {
      const isEntity = source === 'entities'
      const hasTemplateFilter = Boolean(reqParams?.template)
      const params: Record<string, unknown> = {
        page,
        ...reqParams,
      }

      if (search) {
        if (isEntity) {
          setWith(params, 'filter._name.contain', search)
        } else {
          setWith(params, 'search', search)
        }
      }

      if (isEntity && !hasTemplateFilter) {
        setWith(params, 'filter.status.equal', 'publish')
      }

      if (isEntity) {
        setWith(params, 'original', true)
      }

      return httpService
        .get<ListResponse>(`/${source}`, {
          params,
        })
        .then((res) => res.data)
    },
    {
      enabled: isOpen,
      select: (resData) => {
        return {
          data: resData['hydra:member'],
          totalItems: resData['hydra:totalItems'],
        }
      },
      onSuccess: (data) => {
        setTotalItems(data.totalItems)
      },
    }
  )

  const valueQuery = useQuery(
    [QUERY_KEY, 'value', value],
    () => {
      let ids = multiple ? value : [value]
      ids = (ids as string[]).map((val) => getIdFromIri(val))

      if (!multiple) {
        return httpService.get(`/${source}/${ids[0]}`).then((res) => [res.data])
      }

      return httpService
        .get<ListResponse>(`/${source}`, {
          params: {
            ...reqParams,
            id: ids,
          },
        })
        .then((res) => res.data['hydra:member'])
    },
    {
      enabled: Boolean(value),
      keepPreviousData: true,
      staleTime: Infinity,
    }
  )

  const revalidateData = async () => {
    dataQuery.refetch()
    valueQuery.refetch()
  }

  const selectHandler = useCallback(
    (optionValue: Value) => {
      let newValue: ValueProp

      if (multiple && Array.isArray(value)) {
        const oldValue = [...value]
        const selectedIndex = oldValue.findIndex((val) => val === optionValue)

        if (selectedIndex >= 0) {
          oldValue.splice(selectedIndex, 1)
        } else {
          oldValue.push(optionValue)
        }

        newValue = oldValue
      } else if (optionValue === value) {
        newValue = null
      } else {
        newValue = optionValue
      }

      if (isControlled) {
        onChange?.(newValue)
      } else {
        setInternalValue(newValue)
      }
      setAnchorEl(null)
    },
    [isControlled, multiple, onChange, value]
  )

  const { entityModal, actions } = useActions({
    entityTypeIri,
    refetch: revalidateData,
    onCreate: (createdData) => {
      selectHandler(createdData[valueField])
    },
  })

  const searchHandler = useCallback((value: string) => {
    setPage(1)
    setSearch(value)
  }, [])

  const clear = useCallback(() => {
    const newValue = multiple ? [] : ''
    if (isControlled) {
      onChange?.(newValue)
    } else {
      setInternalValue(newValue)
    }
  }, [isControlled, multiple, onChange])

  const isSelected = (option: Option) => {
    if (multiple && Array.isArray(value)) {
      return value.includes(option[valueField])
    }
    return value === option[valueField]
  }

  const menuRef = useRef<HTMLUListElement>(null)

  const getChipLabel = useCallback(
    (val: Value) => {
      if (!multiple && !val) return ''
      const valueObject = valueQuery.data?.find(
        (obj) => obj[valueField].toString() === val?.toString()
      )

      if (!valueObject) {
        return <LinearProgress style={{ width: 40 }} />
      }

      return getOptionLabel(valueObject)
    },
    [getOptionLabel, multiple, valueField, valueQuery.data]
  )

  const valueUi = useMemo(() => {
    if (multiple && Array.isArray(value)) {
      return value.map((item) => (
        <Chip
          key={item}
          label={getChipLabel(item)}
          style={{ margin: 2, maxWidth: '100%' }}
          onDelete={() => selectHandler(item)}
          title={getChipLabel(item)}
        />
      ))
    }

    return <span title={getChipLabel(value)}>{getChipLabel(value)}</span>
  }, [getChipLabel, multiple, selectHandler, value])

  const hasValue = multiple ? Array.isArray(value) && value.length > 0 : Boolean(value)

  const showValueEdit = actions.editHandler && hasValue && !multiple

  return (
    <>
      <TextField
        variant={variant}
        label={label}
        fullWidth
        error={error}
        helperText={helperText}
        size={size}
        onClick={(e) => {
          if (!disabled) {
            setAnchorEl(e.currentTarget)
          }
        }}
        focused={isOpen}
        disabled={disabled}
        required={required}
        className={className}
        data-aqa-form-control={aqaDataAttr}
        InputProps={{
          style: {
            minHeight: size === 'small' ? 40 : 56,
            paddingRight: 7,
            paddingTop: multiple ? 7 : 0,
            paddingBottom: multiple ? 7 : 0,
            cursor: disabled ? 'default' : 'pointer',
            ...(multiple ? { paddingLeft: 7 } : {}),
          },
          inputProps: {
            style: {
              display: 'none',
            },
          },
          readOnly: true,
          startAdornment: hasValue && (
            <InputAdornment
              position="start"
              style={
                multiple
                  ? {
                      display: 'flex',
                      flexWrap: 'wrap',
                      height: 'auto',
                      maxHeight: '100%',
                      width: '100%',
                      paddingRight: 56,
                    }
                  : {
                      display: 'block',
                      width: '100%',
                      height: '100%',
                      maxHeight: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      paddingRight: 56,
                    }
              }
            >
              {valueUi}
            </InputAdornment>
          ),
          endAdornment: (
            <Box
              display="flex"
              alignItems="center"
              style={{ position: 'absolute', right: 7, top: 'calc(50% - 14px)' }}
            >
              {showValueEdit && (
                <IconButton
                  size="small"
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation()
                    const valueId = getIdFromIri(value as string)
                    actions.editHandler?.(+valueId)
                  }}
                >
                  <Edit fontSize="inherit" />
                </IconButton>
              )}
              {hasValue && (
                <IconButton
                  size="small"
                  disabled={disabled}
                  onClick={(e) => {
                    e.stopPropagation()
                    clear()
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              )}
              {isOpen ? (
                <ArrowDropUp className={classes.arrow} />
              ) : (
                <ArrowDropDown className={classes.arrow} />
              )}
            </Box>
          ),
        }}
      />
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
        // PaperProps={{ style: { width: anchorEl?.offsetWidth } }}
      >
        <SearchField
          value={search}
          onChange={searchHandler}
          placeholder="Search by name"
          inDropdown
          actions={
            <>
              {actions.createHandler && (
                <IconButton size="small" onClick={actions.createHandler}>
                  <Add />
                </IconButton>
              )}
            </>
          }
        />
        <MenuList ref={menuRef} style={{ maxHeight: 300, overflowY: 'auto' }}>
          {dataQuery.isLoading &&
            Array.from(Array(10)).map((_, index) => (
              <MenuItem key={index}>
                <Skeleton width="100%" />
              </MenuItem>
            ))}
          {dataQuery.data?.data.map((item) => (
            <MenuItem
              key={item[valueField]}
              onClick={() => selectHandler(item[valueField])}
              selected={isSelected(item)}
              title={getOptionLabel(item)}
              disabled={disabledOptions.includes(item[valueField])}
              className={classes.option}
            >
              <Typography noWrap style={{ maxWidth: 250 }}>
                {getOptionLabel(item)}
              </Typography>
              <Actions
                valueData={item}
                entityId={item.id}
                onEdit={actions.editHandler}
                canDelete={actions.canDelete}
                refetch={revalidateData}
                renderEditAction={renderEditAction}
                getViewPath={actions.getViewPath}
              />
            </MenuItem>
          ))}
        </MenuList>
        {pagesCount > 1 && (
          <Pagination
            page={page}
            count={Math.ceil(totalItems / 30)}
            onChange={(e, page) => setPage(page)}
            color="primary"
            className={classes.pagination}
          />
        )}
      </Popover>
      {entityModal}
    </>
  )
}

export default RelationsSelect
