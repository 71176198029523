import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      position: 'absolute',
      top: 3,
      right: theme.spacing(0.5),
      padding: 0,
      borderRadius: 0,
    },
    root: {
      padding: 28,
      backgroundColor: '#F5F5F5',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      '& $checkbox.selected-item': {
        opacity: 1,
      },
      '& $checkbox': {
        opacity: 0,
      },
      '&:hover $checkbox': {
        opacity: 1,
      },
    },
    content: {
      backgroundColor: '#fff',
      flex: 1,
    },
    preview: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 100,
      cursor: 'pointer',
      '& img': {
        width: 'auto',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    fileIcon: {
      position: 'relative',
      color: '#546E7A',
      '& .MuiIcon-root': {
        fontWeight: 100,
        fontSize: '70px',
      },
    },
    format: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      padding: '0 4px',
      backgroundColor: '#fff',
      border: '1px solid #546E7A',
      borderRadius: 5,
      textTransform: 'uppercase',
    },
    info: {
      padding: 13,
      wordBreak: 'break-all',
    },
  })
)

export default styles
