import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    dropArea: {
      width: '35%',
      transition: 'all .5s ease',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&.no-files': {
        width: '100%',
      },
    },
    filesList: {
      width: '65%',
      overflowX: 'hidden',
      paddingLeft: 20,
      transition: 'width .5s ease',
      '&.no-files': {
        width: 0,
        paddingLeft: 0,
      },
    },
  })
)

export default styles
