// Core
import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// Components
import { DataView, ResourceProps } from 'core/data'
import { FolderColumn, GridItem, NameColumn, Sidebar, SizeColumn } from './components'
import UploadFilesModal from '../upload-files-modal'
import FolderFormModal from '../folder-form-modal'
// Utils
import { DefaultActionColumns, getActions, getTableColumn } from 'core/data/components/data-view'
// Hooks
import { useMediaModalOpen } from 'modules/media/hooks'
import { useAppContext } from 'core/app'
import { useGetDefaultFolder } from 'modules/media/hooks/use-get-default-folder'
// Types
import { Permissions } from 'core/app'
// Redux
import { selectFolder } from '../../store'
import { Icon } from '@material-ui/core'

const MediaList: React.FC<ResourceProps> = (props) => {
  const dispatch = useDispatch()
  const modalOpen = useMediaModalOpen()
  const { t } = useTranslation()
  const {
    actions: { userCan },
  } = useAppContext()

  const defaultFolder = useGetDefaultFolder({
    onSuccess: (data) => {
      if (data) {
        // @ts-ignore
        dispatch(selectFolder(data.id))
      }
    },
  })

  if (defaultFolder.isLoading) return null

  return (
    <>
      <DataView
        showCheckBox
        resourceProps={props}
        controllerOptions={{
          defaultViewType: 'grid',
          filterChangeUrl: false,
          defaultFilters: {
            folder: defaultFolder.data?.id,
          },
        }}
        sidebar={<Sidebar />}
        columns={[
          getActions(
            [
              userCan('media', Permissions.EDIT, 'system')
                ? { icon: <Icon className="icon-edit-3" />, onClick: modalOpen }
                : -1,
              userCan('media', Permissions.DELETE, 'system') ? DefaultActionColumns.DELETE : -1,
            ],
            t
          ),
          getTableColumn({ title: t('table.name'), width: 'auto' }, 'custom', NameColumn),
          getTableColumn({ title: t('table.size'), width: '100' }, 'custom', SizeColumn),
          getTableColumn({ title: t('table.folder') }, 'custom', FolderColumn),
          getTableColumn({ title: t('table.author'), field: 'createdBy' }, 'reference', null, {
            field: 'email',
            source: 'users',
          }),
          getTableColumn({ title: t('table.created'), field: 'createdAt', width: 124 }, 'date'),
        ]}
        hasGrid={true}
        gridItemComponent={GridItem}
      />
      <UploadFilesModal />
      <FolderFormModal />
    </>
  )
}

export default MediaList
