// Core
import { Selector, createSelector } from 'reselect'
// Types
import { IRootState } from 'core/init/types'
import { IFileManagerListState } from './types'

const baseSelector: Selector<IRootState, IFileManagerListState> = (state) => state.mediaManager.list

export const filesListSelector = createSelector(baseSelector, (state) => state.list)
export const filesListParamsSelector = createSelector(baseSelector, (state) => state.params)
export const fileListTotalSelector = createSelector(baseSelector, (state) => state.total)
export const filesListLoadingSelector = createSelector(baseSelector, (state) => state.loading)
export const fileFormModalSelector = createSelector(baseSelector, (state) => state.formModal)
