import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { toggleFileForm } from 'modules/media/store'
import { useListParams } from 'core/data'

type MediaModalOpen = (changeUrl?: boolean) => (rowData: any, onDelete?: () => void) => void

export const useMediaModalOpen: MediaModalOpen = (changeUrl = true) => {
  const dispatch = useDispatch()
  const { methods } = useListParams({})

  return useCallback(
    (rowData, onDelete) => {
      if (changeUrl) methods.setFilter('edit', rowData.id)
      dispatch(toggleFileForm(true, 'edit', rowData, onDelete))
    },
    [changeUrl, dispatch, methods]
  )
}
