import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 'auto'),
      margin: theme.spacing(2, 'auto'),
      borderRadius: theme.spacing(0.5),
      width: '75%',
      '&.no-files': {
        padding: theme.spacing(6, 0),
      },
    },
    fileListHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '65px',
      margin: theme.spacing(1, 0),
      borderBottom: '1px solid #E0E0E0',
    },
    root: {
      display: 'flex',
    },
    dropArea: {
      width: '35%',
      transition: 'all .5s ease',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      border: 'none',
      '&.no-files': {
        width: '50%',
        margin: theme.spacing(0, 'auto'),
      },
    },
    dropAreaWrap: {
      maxWidth: '100%',
      marginBottom: 16,
      whiteSpace: 'normal',
    },
    filesList: {
      width: '65%',
      overflowX: 'hidden',
      paddingLeft: 20,
      transition: 'width .5s ease',
      '&.no-files': {
        width: 0,
        paddingLeft: 0,
      },
    },
  })
)

export default styles
