// Core
import React, { FC, Fragment } from 'react'
import { Button, Divider, InputLabel, List } from '@material-ui/core'
import { FieldArray, getIn } from 'formik'
// Components
import RelationsSelect from 'ui/relations-select'
import ValueItem from './value-item'
import { useTranslation } from 'react-i18next'
// Hooks
import { useReferenceController } from './use-reference-controller'
// Types
import { AttributeData } from 'modules/new-entity/types'
// Styles
import useStyles from './multiple-types-reference.style'

type Props = {
  children?: never
  attributeData: AttributeData
  name: string
  disabled?: boolean
}

const MultipleTypesReference: FC<Props> = (props) => {
  const { attributeData, name, disabled } = props
  const classes = useStyles()
  const {
    //
    entityType,
    setEntityType,
    entity,
    setEntity,
    entityTypeIds,
    addHandler,
  } = useReferenceController(attributeData)
  const { t } = useTranslation()

  return (
    <div>
      <InputLabel className={classes.label}>{attributeData.name}</InputLabel>
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          return (
            <div className={classes.wrapper}>
              <div className={classes.addPanel}>
                <div className={classes.panelSelect}>
                  <RelationsSelect
                    valueField={'@id'}
                    source="entity_types"
                    onChange={(item) => setEntityType(item as string)}
                    value={entityType}
                    reqParams={{ id: entityTypeIds }}
                    label={t('select.entity-type')}
                    disabled={disabled}
                  />
                </div>
                {entityType && (
                  <>
                    <div className={classes.panelSelect}>
                      <RelationsSelect
                        valueField={'@id'}
                        source="entities"
                        reqParams={{ entityType }}
                        value={entity}
                        onChange={(item) => setEntity(item as string)}
                        label={t('select.entity')}
                        disabled={disabled}
                      />
                    </div>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!entity}
                      type="button"
                      onClick={() => addHandler(arrayHelpers)}
                    >
                      Add
                    </Button>
                  </>
                )}
              </div>
              <List className={classes.list}>
                <Divider component="div" />
                {(getIn(arrayHelpers.form.values, name) || []).map(
                  (itemIri: string, index: number) => (
                    <Fragment key={`${itemIri}-${index}`}>
                      <ValueItem entityIri={itemIri} onDelete={() => arrayHelpers.remove(index)} />
                      <Divider component="li" />
                    </Fragment>
                  )
                )}
              </List>
            </div>
          )
        }}
      />
    </div>
  )
}

export default MultipleTypesReference
