import { useQuery } from 'react-query'
// Hooks
import { useSitesContext } from 'modules/sites'
// Services
import { httpService } from 'core/data'
// Types
import { HydraResponse } from 'core/types'
import { Localization } from '../types'
// Utils
import { getIdFromIri } from 'core/utils'

type UseGetSitesType = () => Localization[]

export const useGetSitesLocalizations: UseGetSitesType = () => {
  const { activeSite } = useSitesContext()

  const query = useQuery<any>(['localizations', activeSite?.id], () => {
    const sitesLocalizations = activeSite?.siteLocalizations?.map((localization) => {
      return httpService
        .get<HydraResponse<Localization>>(`/localizations/${getIdFromIri(localization)}`)
        .then((data) => data.data)
    })

    return Promise.all(sitesLocalizations)
  })

  return query?.data
}
