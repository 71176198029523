// Core
import { immerable } from 'immer'
import { v4 as uniqueId } from 'uuid'
// Types
import { IEntityWidget, WidgetType } from './types'

export class EntityWidget implements IEntityWidget {
  [immerable]? = true

  isCreated?: boolean

  id: number

  entitySetRepeats: any[]

  options: {
    container: string
    desktop_width: number
    tablet_width: number
    layout: string
  }

  sortOrder: number

  values: any[]

  widget: any

  widgetType: WidgetType

  constructor(
    container: string,
    layout: string,
    widgetType: WidgetType,
    sortOrder: number,
    values?: any,
    widget: any = null
  ) {
    // @ts-ignore
    this.id = uniqueId()
    this.isCreated = true
    this.entitySetRepeats = values.entitySetRepeats || []
    this.values = values?.values || []
    this.options = {
      container,
      layout,
      desktop_width: 100,
      tablet_width: 100,
    }
    this.widgetType = widgetType
    this.widget = widget
    this.sortOrder = sortOrder
  }
}
