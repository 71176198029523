// Core
import React, { FC, memo, useState } from 'react'
import { TableContainer, Table, TableCell } from '@material-ui/core'
// Components
import EmptyList from '../empty-list'
import { DataTableBody, DataTableHead } from './components'
import Spinner from 'ui/spinner'
// Types
import { Entity } from 'core/types'
// Styles
import { useStyles } from './data-table.styles'

type Props = {
  children?: never
  data?: Entity[]
  columns: any
  loading: boolean
  showCheckBox?: boolean
}

const DataTable: FC<Props> = (props) => {
  const classes = useStyles()
  // eslint-disable-next-line
  const [page, setPage] = useState(0)
  const { data = [], columns, loading, showCheckBox } = props

  if (loading) {
    return <Spinner />
  }

  const createTableRow = (row: any) => {
    return (
      <>
        {columns.map((column: any, index: number) => {
          return (
            <TableCell
              key={`${row['@id'] ?? row['@id']}-${index}`}
              style={{ width: column.width ? column.width : 'auto' }}
            >
              {!column.render ? row[column.field] : column.render(row)}
            </TableCell>
          )
        })}
      </>
    )
  }

  return (
    <TableContainer>
      {data.length === 0 ? (
        <EmptyList />
      ) : (
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size="medium"
        >
          <DataTableHead showCheckBox={showCheckBox} columns={columns} />
          <DataTableBody
            showCheckBox={showCheckBox}
            data={data}
            page={page}
            createTableRow={createTableRow}
          />
        </Table>
      )}
    </TableContainer>
  )
}

export default memo(DataTable)
